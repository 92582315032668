<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<h4>Close Date History</h4>
		<div class="dF aC jSB my-3">
			<div v-if="isInstanceLowrise">
				<a-button type="primary">Tentative/Firm Close Date</a-button>
			</div>
			<div v-else>
				<a-button :type="dateTab == 'occupancy' ? 'primary' : ''"
					@click="dateTab = 'occupancy'; search = ''">Occupancy
					Date</a-button>
				<a-button class="ml-4" :type="dateTab == 'outsideClosing' ? 'primary' : ''"
					@click="dateTab = 'outsideClosing'; search = ''">Outside Occupancy Date</a-button>
			</div>
			<div>
				<a-input v-model="search"
					:placeholder="`Search ${isInstanceLowrise ? 'lot name' : 'unit number'} Here...`"
					style="width: 300px;" allow-clear />
			</div>
		</div>
		<a-table :columns="columns" :dataSource="closeDateHistory" rowKey="id" class="white-table mt-3"
			:loading="loading" :pagination="{
				current: currentPage,
				total: totalCounts,
				pageSize: pageSize,
				showSizeChanger: true,
				pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
			}" @change="handleChange">
			<template slot="lot" slot-scope="record">
				Lot #{{ record.collectionData?.name }} {{ record.collectionData?.block ? ` (Block
				${record.collectionData?.block})` : '' }}
			</template>
			<template slot="unit" slot-scope="record">
				Unit #{{ record.collectionData?.unitNumber }}
			</template>
			<template slot="previousCloseDate" slot-scope="record">
				{{ $formatDate(record.dataBefore.closingDate) }}
			</template>
			<template slot="newCloseDate" slot-scope="record">
				{{ $formatDate(record.dataAfter.closingDate) }}
			</template>
			<template slot="previousOccupancy" slot-scope="record">
				{{ $formatDate(record.dataBefore.occupancy) }}
			</template>
			<template slot="newOccupancy" slot-scope="record">
				{{ $formatDate(record.dataAfter.occupancy) }}
			</template>
			<template slot="previousOutsideClosing" slot-scope="record">
				{{ $formatDate(record.dataBefore.outsideClosing) }}
			</template>
			<template slot="newOutsideClosing" slot-scope="record">
				{{ $formatDate(record.dataAfter.outsideClosing) }}
			</template>
			<template slot="updatedBy" slot-scope="record">
				{{ record.updatedBy?.firstName }} {{ record.updatedBy?.lastName }}
			</template>
			<template slot="updatedAt" slot-scope="record">
				{{ $formatDate(record.updatedAt) }}
			</template>
		</a-table>
	</div>
</template>

<script>
import _ from "lodash";

export default {
	data() {
		return {
			loading: false,
			currentPage: 1,
			totalCounts: 0,
			pageSize: 10,
			search: '',
			closeDateHistory: [],
			sortedInfo: {
				key: "updatedAt",
				order: "DESC",
			},
			dateTab: 'occupancy'
		}
	},
	computed: {
		columns() {
			const list = [
				{
					title: 'Lot #',
					key: 'lot',
					scopedSlots: { customRender: 'lot' },
					sorter: true
				},
				{
					title: 'Previous Close Date',
					key: 'previousCloseDate',
					scopedSlots: { customRender: 'previousCloseDate' },
				},
				{
					title: 'New Close Date',
					key: 'newCloseDate',
					scopedSlots: { customRender: 'newCloseDate' },
				},
				{
					title: 'Updated By',
					key: 'updatedBy',
					scopedSlots: { customRender: 'updatedBy' },
					sorter: true
				},
				{
					title: 'Updated Date',
					key: 'updatedAt',
					scopedSlots: { customRender: 'updatedAt' },
					sorter: true
				}
			]
			if (this.isInstanceLowrise) {
				return list;
			} else {
				const unitColumns = [
					{
						title: 'Unit #',
						key: 'unit',
						scopedSlots: { customRender: 'unit' },
						sorter: true
					}
				];

				if (this.dateTab === 'occupancy') {
					unitColumns.push(
						{
							title: 'Previous Occupancy Date',
							key: 'previousOccupancy',
							scopedSlots: { customRender: 'previousOccupancy' },
						},
						{
							title: 'New Occupancy Date',
							key: 'newOccupancy',
							scopedSlots: { customRender: 'newOccupancy' },
						}
					);
				} else {
					unitColumns.push(
						{
							title: 'Previous Outside Closing Date',
							key: 'previousOutsideClosing',
							scopedSlots: { customRender: 'previousOutsideClosing' },
						},
						{
							title: 'New Outside Closing Date',
							key: 'newOutsideClosing',
							scopedSlots: { customRender: 'newOutsideClosing' },
						}
					);
				}

				return unitColumns.concat(list.slice(3));
			}
		},

		instance() {
			return this.$store.state.instance;
		},

		isInstanceLowrise() {
			return this.instance.productType === 'lowrise';
		},

		eventQuery() {
			if (this.isInstanceLowrise) {
				return `event=${encodeURIComponent('lot:closingDateChanged')}`;
			} else {
				return `event=${encodeURIComponent(this.dateTab === 'occupancy' ? 'unit:occupancyDateChanged' : 'unit:outsideClosingDateChanged')}`;
			}
		},

		collectionQueryName() {
			return `&collectionQuery[collectionName]=${this.isInstanceLowrise ? 'lot' : 'unit'}`;
		},

		searchQuery() {
			let query = ''
			if (this.isInstanceLowrise) {
				query += `&collectionQuery[_where][_or][0][name_contains]=${encodeURIComponent(this.search)}&collectionQuery[_where][_or][1][block_contains]=${encodeURIComponent(this.search)}`;
			} else {
				query += `&collectionQuery[unitNumber]=${encodeURIComponent(this.search)}`;
			}
			return query
		},

		searchAndSortQuery() {
			let query = '';

			if (['lot', 'unit'].includes(this.sortedInfo.key)) {
				query = `${this.collectionQueryName}&collectionQuery[_sort]=${this.sortedInfo.key === 'lot' ? 'name' : 'unitNumber'}:${this.sortedInfo.order}`;
				if (this.search) {
					query += `${this.searchQuery}`;
				}
			} else {
				query = `&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`;
				if (this.search) {
					query += `${this.collectionQueryName}${this.searchQuery}`;
				}
			}

			return query
		}
	},
	watch: {
		dateTab() {
			this.getHistoryTotalCounts();
			this.getHistoryData();
		},

		search() {
			this.searchDebounce();
		}
	},
	methods: {
		searchDebounce: _.debounce(function () {
			this.currentPage = 1;
			this.getHistoryData();
			this.getHistoryTotalCounts();
		}, 1000),

		async getHistoryTotalCounts() {
			try {
				let searchQuery = '';
				if (this.search) {
					searchQuery = `${this.collectionQueryName}${this.searchQuery}`;
				}
				const { data } = await this.$api.get(`/audit-logs/:instance/count?${this.eventQuery}${searchQuery}`)
				this.totalCounts = data;
			} catch (error) {
				this.$toastError(error, `Error while fetching close date history counts. Please try again later.`)
			}
		},

		async getHistoryData() {
			this.loading = true;
			try {
				const paginationQuery = `&_start=${(this.currentPage - 1) * this.pageSize}&_limit=${this.pageSize}`;
				const { data } = await this.$api.get(`/audit-logs/:instance?${this.eventQuery}${paginationQuery}${this.searchAndSortQuery}`);
				this.closeDateHistory = data;
			} catch (error) {
				this.$toastError(error, `Error while fetching close date history. Please try again later.`)
			} finally {
				this.loading = false;
			}
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
				this.pageSize = pagination.pageSize;
			}
			if (sorter?.column) {
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getHistoryData();
		},
	},
	created() {
		this.$store.commit("UPDATE_CRUMBS", 'closeDateLog');
		this.getHistoryTotalCounts()
		this.getHistoryData()
	},
}
</script>
