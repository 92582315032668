<template>
    <div class="dF f1" style="flex-direction: column;">
        <BulkTags
            :visible="showBulkTags"
            @applyBulkTags="applyBulkTags"
            @closeBulkTags="closeBulkTags"
            :selectedLots="selectLot"
            :lots="lots"
        />
		<BulkStatus
            :visible="showBulkStatus"
            @applyBulkStatus="applyBulkStatus"
            @closeBulkStatus="closeBulkStatus"
            :selectedLots="selectLot"
            :lots="lots"
        />
		<CloseDateUpdate
            :visible="showCloseDateUpdate"
			:closingDate="closingDate"
            @updateDate="updateClosingDate"
            @closeDateUpdate="closeBulkCloseDate"
            :selectedLots="selectLot"
        />
		<div class="dF aC jSB">
        	<h3>Overview</h3>
			<div class="aC" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 0.5rem;">
				<div style="cursor: pointer;" @click="onViewChange('grid')">
					<!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" :stroke="currentView == 'grid' ? `var(--orange)` : `#303B42`" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg> -->
					<i :style="currentView == 'grid' ? 'color:var(--orange)' : 'color:#D3D0D6'" style="cursor:pointer; font-size:25px" class="fa fa-th" />
				</div>
				<!-- <div style="cursor: pointer;" @click="onViewChange('list')">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" :stroke="currentView == 'list' ? `var(--orange)` : `#303B42`" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
				</div> -->
				<div style="cursor: pointer;" @click="onViewChange('map')">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22.988 20.434"> <path id="Icon_awesome-map-marked" data-name="Icon awesome-map-marked" d="M11.494,0A5.029,5.029,0,0,0,6.465,5.029c0,2.245,3.287,6.338,4.546,7.823a.629.629,0,0,0,.966,0c1.259-1.485,4.546-5.578,4.546-7.823A5.029,5.029,0,0,0,11.494,0ZM.8,8.619A1.277,1.277,0,0,0,0,9.8v9.99a.639.639,0,0,0,.876.593l5.51-2.508v-9.3a12.086,12.086,0,0,1-.848-1.853Zm10.691,5.736a1.907,1.907,0,0,1-1.457-.677c-.785-.926-1.619-1.98-2.374-3.062v7.264l7.663,2.554V10.616c-.755,1.081-1.589,2.136-2.374,3.062A1.908,1.908,0,0,1,11.494,14.354ZM22.112,6.432,16.6,8.94V20.434L22.185,18.2a1.277,1.277,0,0,0,.8-1.186V7.025A.639.639,0,0,0,22.112,6.432Z" :fill="currentView == 'map' ? `var(--orange)` : `#D3D0D6`"/> </svg>
				</div>
			</div>
		</div>
		<template v-if="currentView == 'grid' || currentView == 'list'">
			<div class="dF" style="flex-wrap: wrap;">
				<div
					v-for="stat in lotOverview"
					:key="stat.value"
					class="overview-card mb-3 mr-3"
					style="width: 135px;"
				>
					<h5 style="min-height: 45px;">{{ stat.label }}</h5>
					<div
						style="font-size: 25px; margin-top: 20px"
						:style="`color:${stat.color}`"
					>
						{{ overviewCount[stat.value] }}
					</div>
				</div>
			</div>
			<div class="my-4 jSB dF aC" v-if="currentWidth >= 1280">
				<div class="dF aC">
					<h3 class="mr-3">Lots</h3>
					<div class="dF">
						<div
							v-for="(stat, statI) in lotOverview"
							:key="stat.value"
							:class="statI != 0 ? 'ml-2' : ''"
							:style="
								stat.value != 'total'
									? `background-color:${stat.color};`
									: 'border:1px solid #D0C9D6'
							"
							style="border-radius: 4px"
							class="px-3 py-1"
						>
							<a-checkbox
								@change="(e) => changeSelect(stat.value, e)"
								:checked="selectType.includes(stat.value)"
								:style="
									stat.value != 'total' && stat.value != 'hold'
										? 'color:#FFF'
										: ''
								"
								>{{ stat.select }}</a-checkbox
							>
						</div>
					</div>
				</div>
				<div class="dF aC">
					<MoreActions
						v-if="selectLot.length > 1"
						shape="button"
						buttonText="Bulk Actions"
						:items="bulkActions"
						@action="bulkActioned"
					/>
					<div
						@click="openFilter"
						class="dF aC px-3 py-1 mx-2"
						style="
							border-radius: 3px;
							background-color: #fff;
							cursor: pointer;
							border: 1px solid #ecebed;
						"
					>
						<div>Filters</div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-filter ml-2"
						>
							<polygon
								points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
							></polygon>
						</svg>
					</div>
				</div>
			</div>
			<div v-else class="my-4">
				<div class="dF aC jSB" style="gap: 15px">
					<h3>Lots</h3>
					<div class="dF" style="flex-wrap: wrap;">
						<div
							v-for="(stat) in lotOverview"
							:key="stat.value"
							:style="
								stat.value != 'total'
									? `background-color:${stat.color};`
									: 'border:1px solid #D0C9D6'
							"
							style="border-radius: 4px"
							class="px-3 py-1 mb-2 mr-2"
						>
							<a-checkbox
								@change="(e) => changeSelect(stat.value, e)"
								:checked="selectType.includes(stat.value)"
								:style="
									stat.value != 'total' && stat.value != 'hold'
										? 'color:#FFF'
										: ''
								"
								>{{ stat.select }}</a-checkbox
							>
						</div>
					</div>
					<div class="dF aC">
						<MoreActions
							v-if="selectLot.length > 1"
							shape="button"
							buttonText="Bulk Actions"
							:items="bulkActions"
							@action="bulkActioned"
						/>
						<div
							@click="openFilter"
							class="dF aC px-3 py-1 mx-2"
							style="
								border-radius: 3px;
								background-color: #fff;
								cursor: pointer;
								border: 1px solid #ecebed;
							"
						>
							<div>Filters</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-filter ml-2"
							>
								<polygon
									points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
								></polygon>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div v-if="lots.length" class="grid-display-2">
				<div v-for="lot in lots" :key="lot.id">
					<LotCard
						@toggleSelect="toggleSelect"
						:id="lot.id"
						:selectLot="selectLot"
						@updateStatus="updateStatus(lot)"
						@updateCloseDate="updateCloseDate(lot)"
						@unSell="unsellLot(lot.id)"
						@lotDetails="openModal(lot.id, 'lotDetails')"
						@applyTags="updateTags"
						@makeTransaction="makeTransaction(lot, $event)"
						@createWorksheet="createWorksheet(lot)"
					/>
				</div>
			</div>
			<div v-else>
				<EmptyApp
					:text="'No lots found for your filter'"
					:img="'https://bildhive.nyc3.digitaloceanspaces.com/v3/inventory_empty_d168efc25f.png'"
				/>
			</div>
		</template>
		<div v-show="currentView == 'map'" class="f1 relative">
			<BhLoading :show="loading" :loadingText="true" />
			<div class="w-full dF">
				<div class="f1 dF fC relative" style="overflow-x: auto;">
					<div class="py-3 w-full" style="background-color: transparent; overflow-y: hidden;">
						<div class="w-full dF pb-3" style="overflow-x: auto;">
							<div
								v-for="(stat, statI) in lotOverview"
								:key="stat.value"
								:class="statI != 0 ? 'ml-2' : ''"
								:style="
									stat.value != 'total'
										? `background-color:${stat.color};`
										: 'border:1px solid #D0C9D6'
								"
								style="border-radius: 4px; display: inline-block; white-space: nowrap;"
								class="px-3 py-1"
							>
								<a-checkbox
									@change="(e) => changeSelect(stat.value, e)"
									:checked="selectType.includes(stat.value)"
									:style="
										stat.value != 'total' && stat.value != 'hold'
											? 'color:#FFF'
											: ''
									"
									style="flex:1;"
									>{{ stat.select }}</a-checkbox
								>
							</div>
						</div>
					</div>

					<div class="dF">
						<div id="siteplan-lot-tooltip" class="siteplan-tooltip-style1" role="tooltip" style="z-index:3; opacity: 0">
							<div>
								<span>My tooltip</span>
							</div>
						</div>
						<div class="siteplan-holder relative w-full mb-2" ref="siteplan" :style="[showElevationDrawer ? {'padding-bottom': 'calc(100vh - 517px - 0.75em)'} : {'padding-bottom': 'calc(100vh - 327px - 0.75em)'}]" style="height: 0;">
							<div class="d-none d-lg-block absolute dF jE px-4 pb-4" style="top: 1em; right: 0; z-index: 2;">
								<svg width="30" height="30" viewBox="0 0 49.001 48.999" class="mr-2 zoomOut"  @click="zoom('out')">
									<path d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-30.591-26.865h0a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h19.93a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659c-.061.05-6.96.112-12.512.112C-7613.835-2692.253-7618.34-2692.268-7618.59-2692.365Z" transform="translate(7633.5 2714.5)" fill="#333333" opacity="0.812"/>
								</svg>
								<svg width="30" height="30" viewBox="0 0 49.001 48.999" class="zoomIn" @click="zoom('in')">
									<path  d="M-7588-2665.5h-42a3.5,3.5,0,0,1-3.5-3.5v-42a3.5,3.5,0,0,1,3.5-3.5h42a3.5,3.5,0,0,1,3.5,3.5v42A3.5,3.5,0,0,1-7588-2665.5Zm-31.091-26.865a1.661,1.661,0,0,0-1.659,1.659v1.661a1.662,1.662,0,0,0,1.659,1.661h7.474v7.473a1.663,1.663,0,0,0,1.661,1.661h1.661a1.663,1.663,0,0,0,1.661-1.661v-7.473h7.473a1.663,1.663,0,0,0,1.661-1.661v-1.661a1.662,1.662,0,0,0-1.661-1.659h-7.473v-7.473a1.663,1.663,0,0,0-1.661-1.661h-1.661a1.663,1.663,0,0,0-1.661,1.661v7.473Z" transform="translate(7633.5 2714.5)" fill="#333333" opacity="0.812"/>
								</svg>
							</div>
							<canvas class="w-full h-full absolute" style="background-color: #f2f2f2; top: 0; left: 0;" ref="canvas"></canvas>

							<!-- Model Type Filter -->
							<div style="cursor: pointer; user-select: none; top: 20px; left: 20px; position: absolute; z-index: 2;">
								<div @click="showFilterExpanded = !showFilterExpanded" class="filter-dropdown dF aC">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
									<div class="ml-3" style="line-height: 1.5; font-size: .75rem; width: 200px;">
										<div style="color: #9EA0A5;">Home Model Types</div>
										<div>All Types</div>
									</div>
									<div class="flex items-center justify-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
									</div>
								</div>
								<div data-filter-1 class="filter-dropdown-expanded" v-show="showFilterExpanded" style="overflow-y: scroll; max-height: 180px;">

								</div>
							</div>
						</div>

						<div class="unit-list" style="background-color: #FFFFFF; overflow-y: hidden; border: 1px solid #e8e8e8;" :style="[showElevationDrawer ? {'height': 'calc(100vh - 517px - 0.75em)'} : {'height': 'calc(100vh - 327px - 0.75em)'}]" :class="showing.lot ? 'active' : ''" :key="showing.lot">
							<div class="w-full h-full pt-3 px-4 pb-4" style="overflow-y: scroll;">
								<div class="w-full dF jE">
									<a-icon type="close" @click="resetLot" />
								</div>
								<div class="text-md" style="color: #000; font-weight: bold;">
									<template v-if="showingLot.units && showingLot.units.length">
										Available Models for
									</template>
									<template v-else>
										There are no Available Homes for
									</template>

									<span style="display: inline-block;">Lot {{showingLot.name}}{{ showingLot.block ? ` (Block ${showingLot.block})` : '' }}</span>

								</div>
								<div class="dF fC">

									<div class="dF fC mt-4" style="overflow: hidden;" v-for="item in showingLot.units" :key="item.id" @click="showUnit(item.id)">

										<div class="h-0 bg-center bg-cover bg-no-repeat" style="padding-bottom: 66%;" :style="{backgroundImage:`url(${item.image})`}">

										</div>
										<div class="mt-1">
											<div style="color: #000">
												<strong class="text-md" style="font-weight: bold;">{{item.unitGroup.name}} {{item.name}}</strong>
												<div class="text-sm">{{item.unitGroup.size + "' " + item.unitGroup.type}}</div>
											</div>
											<div class="mt-2 py-1 text-xs" :style="`border-top: 1px solid #000; border-bottom: 1px solid #000; color: #000;`">
												{{item.package.sqft && item.package.sqft.toLocaleString() || 0}} Sq. Ft. | {{'Beds: ' + item.package.beds}} | {{'Baths: ' + item.package.baths}}<span v-if="item.package.powderRooms"> | {{'Pwdr: ' + item.package.powderRooms}}</span>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="p-3 w-full select-elevation" :class="showElevationDrawer ? 'active': ''" style="background-color: #FFFFFF; border: 1px solid #e8e8e8; overflow-y: hidden;">
						<div  class="mb-3 dF aC jSB" style="font-weight: bold; font-size: 16px; cursor: pointer;">
							<div class="dF aC">
								<div>Select an Elevation</div>
								<div v-if="selectedElev" style="display:inline-block">
									<div @click="clearElevation" class="dF aC ml-3 py-2 px-4" style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer"><div class="mr-3">Clear Elevation</div><a-icon style="color:var(--danger)" type="delete" /></div>
								</div>
							</div>
							<div class="f1 dF jE" @click="toggleElevation">
								<a-icon v-if="showElevationDrawer" type="down"></a-icon>
								<a-icon v-else type="up"></a-icon>
							</div>
						</div>
						<div class="w-full dF" style="overflow-x: auto;">
							<template v-if="filteredUnits.length">
								<template v-for="(elev,elevI) in filteredUnits">
									<ElevationCard :elev="elev" :selectedElev="selectedElev" :key="elev+elevI" @selectElevation="showElevation" />
								</template>
							</template>
							<template v-else>
								<div class="dF jC h-full w-full aC">No units found</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>

        <a-modal
            :visible="modal.visible"
            @cancel="onClose"
            :centered="true"
            :footer="null"
            :width="'35%'"
        >
            <h5 v-if="modal.object.name">
                {{ `Lot ${modal.object.name}` }}
                <span class="ml-3" :style="`color:${statusColorCode[modal.object.status]}`">
					{{ salesStatus[modal.object.status] }}
				</span>
            </h5>
            <a-form-model
                v-if="modal.type == 'updateStatus'"
                ref="status"
                :model="status"
                class="mt-5"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Status"
                            required
                            :rules="req('Please select one')"
                            prop="status"
                        >
                            <a-select
                                @change="changeStatus"
                                v-model="status.status"
                            >
                                <a-select-option
                                    v-for="stat in statusOptions"
                                    :key="stat.value"
                                    :value="stat.value"
                                    >{{ stat.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col
                        :span="24"
                        v-if="['sold', 'inventory', 'conditional', 'soldConditional', 'hold'].includes(status.status)"
                    >
                        <a-form-model-item
                            label="Which Elevation"
                            required
                            :rules="req('Please select one')"
                            prop="elevation"
                        >
                            <a-select
                                @change="changeElevation"
                                v-model="status.elevation"
                            >
                                <a-select-option
                                    v-for="unit in modal.object.units"
                                    :key="
                                        typeof unit == 'string' ? unit : unit.id
                                    "
                                    :value="
                                        typeof unit == 'string' ? unit : unit.id
                                    "
                                    >{{
                                        typeof unit == "string" && units[unit]
                                            ? `${units[unit].unitGroup.name} ${units[unit].name}`
                                            : typeof unit == "object" &&
                                              units[unit.id]
                                            ? `${
                                                  units[unit.id].unitGroup.name
                                              } ${units[unit.id].name}`
                                            : "N/A"
                                    }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <div v-if="(['sold', 'inventory', 'conditional', 'soldConditional'].includes(status.status)) && status.elevation != ''">
                        <a-col :span="24">
                            <a-form-model-item
                                label="Which Package"
                                required
                                :rules="req('Please select one')"
                                prop="package"
                            >
                                <a-select
                                    v-model="status.package"
                                    @change="changePackage"
                                >
                                    <template v-if="status.elevation != ''">
                                        <a-select-option
                                            v-for="pack in units[
                                                status.elevation
                                            ].packages"
                                            :key="pack.id"
                                            :value="pack.id"
                                            >{{ pack.name }}</a-select-option
                                        >
                                    </template>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <div v-if="bricks.length">
                            <a-col :span="24">
                                <a-form-model-item
                                    label="Select Exterior Color Package"
                                    prop="brick"
                                >
                                    <a-select v-model="status.brick">
                                        <a-select-option
                                            :value="item.id"
                                            v-for="item in bricks"
                                            :key="item.id"
                                        >
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                        </div>
                    </div>
                    <a-col
                        :span="24"
                        v-if="
                            (status.status == 'sold' ||
                                status.status == 'conditional' || status.status == 'soldConditional') &&
                            status.elevation &&
                            status.package
                        "
                    >
                        <a-form-model-item
                            label="Final Price"
                            prop="price"
                            required
                            :rules="req('Please input a final price')"
                        >
                            <a-input-number
                                style="width: 100%"
                                v-model="status.price"
                                :formatter="
                                    (price) =>
                                        `$ ${price}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                "
                                :min="0"
                            ></a-input-number>
                        </a-form-model-item>
                    </a-col>
                    <a-col
                        :span="24"
                        v-if="
                            (status.status == 'sold' ||
                                status.status == 'conditional' || status.status == 'soldConditional') &&
                            status.elevation &&
                            status.package
                        "
                    >
                        <a-form-model-item
                            label="Sales Owner"
                            prop="owner"
                            required
                            :rules="req('Please select one')"
                        >
                            <a-select
                                v-model="status.owner"
                                placeholder="Select one"
                                size="large"
                            >
                                <a-select-option
                                    v-for="user in users"
                                    :key="user.id"
                                    :value="user.id"
                                    >{{
                                        `${user.firstName} ${user.lastName}`
                                    }} ({{ user.email }})</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col
                        :span="24"
                        v-if="
                            (status.status == 'sold' ||
                                status.status == 'conditional' || status.status == 'soldConditional') &&
                            status.elevation &&
                            status.package
                        "
                    >
                        <a-form-model-item
                            label="Purchasers"
                            prop="purchasers"
                            required
                            :rules="req('Please add purchasers')"
                        >
                            <a-select
                                v-model="status.purchasers"
                                placeholder="Select add purchasers"
                                mode="tags"
                                size="large"
                            >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <a-form-model
                v-else-if="modal.type == 'makeTransaction'"
                ref="transaction"
                :model="transaction"
                class="mt-5"
            >
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item
                            label="Status"
                            required
                            :rules="req('Please select one')"
                            prop="status"
                        >
                            <a-select v-model="transaction.status">
                                <a-select-option
                                    v-for="stat in statusOptions"
                                    :key="stat.value"
                                    :value="stat.value"
                                    >{{ stat.label }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item
                            label="Which Elevation"
                            required
                            :rules="req('Please select one')"
                            prop="elevation"
                        >
                            <a-select v-model="transaction.elevation">
                                <a-select-option
                                    v-for="unit in modal.object.units"
                                    :key="unit"
                                    :value="unit"
                                    >{{
                                        `${units[unit].unitGroup.name} ${units[unit].name}`
                                    }}</a-select-option
                                >
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-model-item
                            label="Final Price"
                            required
                            :rules="req('Please fill in the price')"
                            prop="price"
                        >
                            <a-input-number
                                style="width: 100%"
                                v-model="transaction.price"
                                :formatter="
                                    (price) =>
                                        `$ ${price}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                "
                                :min="0"
                            ></a-input-number>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div v-else-if="modal.type === 'lotDetails'">
                <div class="grid-display-4 gap-5 mt-3">
                    <div v-if="modal.object.attachedUnit">
                        <div style="color: #9ea0a5">Sold Elevation</div>
                        <div>
                            <strong>{{
                                getUnitName(modal.object.attachedUnit)
                            }}</strong>
                        </div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Price</div>
                        <div>{{ `$${calculatePrice(modal.object.id)}` }}</div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Model Type</div>
                        <div>{{ getModelType(modal.object.id) }}</div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Frontage</div>
                        <div>{{ getFrontage(modal.object.id) }}</div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Block #</div>
                        <div>
                            {{
                                !modal.object.block ? "N/A" : modal.object.block
                            }}
                        </div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Street Name</div>
                        <div>
                            {{
                                !modal.object.street
                                    ? "N/A"
                                    : modal.object.street
                            }}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="grid-display-2 gap-5 mt-3">
                    <div>
                        <div style="color: #9ea0a5">Elevations</div>

                        <div class="dF aC">
                            <CommaSep
                                v-if="getUnits(modal.object.id).length"
                                :items="getUnits(modal.object.id)"
                            />
                            <span v-else>N/A</span>
                        </div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Condition</div>
                        <div class="dF aC">
                            <CommaSep
                                v-if="getCondition(modal.object.id).length"
                                :items="getCondition(modal.object.id)"
                            />
                            <span v-else>N/A</span>
                        </div>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">
                            Premium (Total:
                            {{
                                `$${getPremium(modal.object.id).toLocaleString(
                                    "us-en"
                                )}`
                            }})
                        </div>
                        <CommaSep
                            v-if="getPremiums(modal.object.id).length"
                            :items="getPremiums(modal.object.id)"
                        />
                        <span v-else>N/A</span>
                    </div>
                    <div>
                        <div style="color: #9ea0a5">Grading</div>
                        <div class="dF aC">
                            <CommaSep
                                v-if="getGradings(modal.object.id).length"
                                :items="getGradings(modal.object.id)"
                            />
                            <span v-else>N/A</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="modal.type === 'applyTags'" class="py-3">
                <h5>Apply Tags</h5>
                <div
                    v-for="item in $store.state.inventory.tags"
                    :key="item.id"
                    @click="applyTags(item.id)"
                    :style="
                        applyingTags.includes(item.id)
                            ? 'background-color: #CAC3D0'
                            : ''
                    "
                    class="py-1 px-2 filter-options my-4"
                    style="
                        border: #d0c9d6 1px solid;
                        border-radius: 6px;
                        width: 100%;
                        cursor: pointer;
                        text-align: center;
                    "
                >
                    {{ item.name }}
                </div>
            </div>
            <div style="width: 100%" class="dF jE mt-4">
                <div class="dF aC" v-if="modal.type !== 'lotDetails'">
                    <a-button
                        :style="{ marginRight: '8px' }"
                        @click="onClose"
                        style="
                            border: none !important;
                            background-color: #ece9f1;
                            width: 150px;
                        "
                        >CANCEL</a-button
                    >
                    <a-button
                        v-if="modal.type === 'applyTags'"
                        @click="updateTags"
                        type="primary"
                        style="width: 150px"
                        >UPDATE TAGS</a-button
                    >
                    <a-button
                        v-else-if="modal.type === 'updateStatus'"
                        @click="submit"
                        type="primary"
                        style="width: 150px"
                        >UPDATE</a-button
                    >
                </div>
            </div>
        </a-modal>
		<a-modal
            :visible="showTMInfoTip"
            @cancel="
                showTMInfoTip = false;
                doNotShowTMInfoTipAgain = false;
            "
            :centered="true"
            title="Tip"
            okText="CONTINUE"
            @ok="tmInfoUpdate"
        >
            <h6>
                When using Bildhive’s transaction management (TM) use only the
                hold option. Applying “conditional or sold options” will remove
                the model/unit and will therefore not show up in the (TM)
                application.
            </h6>
            <h6 class="mt-4">
                If you’re not using TM as part of your process, feel free to use
                all status options to manage your inventory.
            </h6>

            <a-checkbox class="mt-4" v-model="doNotShowTMInfoTipAgain">
                Don’t show me this message again</a-checkbox
            >
        </a-modal>
    </div>
</template>

<script>
import CommaSep from "bh-mod/components/common/CommaSep";
import LotCard from "@/components/inventory/LotCard";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import MoreActions from "bh-mod/components/common/MoreActions";
import BulkTags from "@/components/inventory/BulkTags";
import BulkStatus from "@/components/inventory/BulkStatus";
import CloseDateUpdate from "@/components/inventory/CloseDateUpdate";
import ElevationCard from '@/components/inventory/ElevationCard';
import BhLoading from 'bh-mod/components/common/Loading';
import initSp from "../../siteplan.js";
import moment from "moment";
import ResizeObserver from 'resize-observer-polyfill';

export default {
    components: {
        CommaSep,
        LotCard,
        EmptyApp,
        MoreActions,
        BulkTags,
		BulkStatus,
		CloseDateUpdate,
		ElevationCard,
		BhLoading
    },
    computed: {
		availableUnits() {
            return this.$store.state.inventory.availableUnits || {};
        },
		filteredUnits(){
			let units = Object.values(this.units)
			if(this.selectedGroup != 'all'){
				units = units.filter(unit => unit.unitGroup.type == this.selectedGroup.type && unit.unitGroup.size == this.selectedGroup.size)
			}
			console.log('FILTERED UNITS', units)
			return units
		},
		currentView(){
			return this.$store.state.inventory.currentView
		},
        overviewCount() {
            return this.$store.getters.getCount;
        },
        filter() {
            return this.$store.state.inventory.lowriseFilter;
        },
        lots() {
            let state = this.$store.state.inventory;
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.name) < parseInt(b.name)) return -1;
                    if (parseInt(a.name) > parseInt(b.name)) return 1;
                    else return 0;
                });
                return array;
            };

            let data = state.lowriseFilter;

            let statuses = this.selectType;

            if (
                statuses && statuses[0] == "total" &&
                data.modelType && data.modelType[0] == "any" &&
                data.street == "any" &&
                data.tags && data.tags[0] === "any" &&
                data.frontage && data.frontage[0] == 0 &&
				data.unitGroup == "any" &&
				data.elevation == "any" &&
                data.premiums && data.premiums[0] == "any" &&
                data.price && data.price[1] == 0
            ) {
                return orderAlpha(Object.values(state.lots));
            }

            const checkPrice = (lot, prices) => {
                if (prices[1] == 0) return true;

                let list = [];

                Object.keys(this.lotsByPremiums.priceMap).forEach((price) => {
                    if (price >= prices[0] && price <= prices[1]) {
                        list = list.concat(this.lotsByPremiums.priceMap[price]);
                    }
                });

                if (list.includes(lot.id)) return true;
                else return false;
            };

            const checkPremiums = (lot, premiums) => {
                if (premiums[0] == "any") return true;
				if (premiums[0] == "none"){
					if (lot.other && lot.other.premiums) {
						let list = [];
						Object.keys(lot.other.premiums).forEach((key) => {
							if (lot.other.premiums[key].active && this.allPremiums.includes(lot.other.premiums[key].id)) list.push(key);
						});
						if (list.length == 0) return true;
						else return false;
					}
					else return true;
				}

                let list = [];
                if (lot.other && lot.other.premiums) {
                    Object.keys(lot.other.premiums).forEach((key) => {
                        if (lot.other.premiums[key].active) list.push(key);
                    });
                }

                let pass = false;
                list.forEach((prem) => {
                    if (premiums.includes(prem)) pass = true;
                });

                return pass;
            };

            const checkFrontage = (lot, frontages) => {
                if (frontages[0] == 0) return true;

                let pass = true;
                lot.units.forEach((unit) => {
                    let id = unit;
                    if (unit.id) id = unit.id;
                    if (
                        !state.units[id] ||
                        !state.units[id].unitGroup ||
                        !frontages.includes(state.units[id].unitGroup.size)
                    )
                        pass = false;
                });
                return pass;
            };

			const checkUnitGroup = (lot, unitGroup) => {
                if (unitGroup == "any") return true;

                let pass = false;
                lot.units.forEach((unit) => {
                    let id = unit;
                    if (unit.id) id = unit.id;
                    if (
                        state.units[id] &&
                        state.units[id].unitGroup &&
                        unitGroup == state.units[id].unitGroup.id
                    )
                        pass = true;
                });
                return pass;
            };

			const checkElevation = (lot, elevation) => {
                if (elevation == "any") return true;

                let pass = false;
				if (!(lot.units && lot.units.length)) return false;
                lot.units.forEach((unit) => {
                    let id = unit;
                    if (unit.id) id = unit.id;
                    if (
                        state.units[id] &&
                        elevation == state.units[id].name
                    )
                        pass = true;
                });
                return pass;
            };

            const checkModelType = (lot, types) => {
                if (types[0] === "any") return true;

                let pass = true;
                lot.units.forEach((unit) => {
                    let id = unit;
                    if (unit.id) id = unit.id;
                    if (
                        !state.units[id] ||
                        !state.units[id].unitGroup ||
                        !types.includes(state.units[id].unitGroup.type)
                    )
                        pass = false;
                });
                return pass;
            };

            const checkTags = (lot, tags) => {
                if (tags[0] === "any") return true;

                const isFound = (arr1, arr2) => {
                    let found = false;
                    arr1.forEach((str) => {
                        if (arr2.includes(str)) found = true;
                    });
                    return found;
                };

                let pass = true;
                if (!isFound(lot.tags, tags)) pass = false;
                return pass;
            };

            const checkStatus = (lot, status) => {
                if (lot.publishStatus == null) lot.publishStatus = "draft";
                if (status[0] === "total") return true;
				if (lot.status == 'conditional' || lot.status == 'soldConditional'){
					if (status.includes('conditional')) return true;
				} else {
					return (
                   	 	status.includes(lot.status) ||
						status.includes(lot.publishStatus)
					);
				}
            };
            const checkStreet = (lot, street) => {
                if (street == "any") return true;
                return lot.street == street;
            };

            return orderAlpha(
                Object.values(state.lots).filter((lot) => {
                    if (!checkModelType(lot, data.modelType)) return false;
                    if (!checkTags(lot, data.tags)) return false;
                    if (!checkStatus(lot, statuses)) return false;
                    if (!checkStreet(lot, data.street)) return false;
                    if (!checkFrontage(lot, data.frontage)) return false;
					if (!checkUnitGroup(lot, data.unitGroup)) return false;
					if (!checkElevation(lot, data.elevation)) return false;
                    if (!checkPremiums(lot, data.premiums)) return false;
                    if (!checkPrice(lot, data.price)) return false;
                    return true;
                })
            );
        },
        storeLots() {
            return this.$store.state.inventory.lots;
        },
		unitGroups(){
			let unitGroups = Object.values(this.$store.state.inventory.unitGroups)
			let allUnitGroups = []
			let allUnitGroupsNames = []
			unitGroups.forEach(x => {
				let name = x.type + ' ' + x.size
				let type = x.type
				let size = x.size
				let obj = {
					name: name,
					type: type,
					size: size
				}
				if (!allUnitGroupsNames.includes(name)){
					allUnitGroupsNames.push(name)
					allUnitGroups.push(obj)
				}
			})
			console.log('ALL UNIT GROUPS', allUnitGroups)
			allUnitGroups = allUnitGroups.sort((a,b) => {
				//sort by size
				if (a.size < b.size) return -1
				if (a.size > b.size) return 1
			})
			return allUnitGroups
		},
        models() {
            let models = {};
            let units = JSON.parse(
                JSON.stringify(this.$store.state.inventory.units)
            );
            Object.values(units).forEach((unit) => {
                if (unit.buildingType == "models") {
                    let model = {};
                    let price = [];
                    model = JSON.parse(JSON.stringify(unit));
                    model.packages.forEach((x) => {
                        if (x.price != 0 || x.price != "") {
                            price.push(x.price);
                        }
                    });
                    if (price.length != 0) {
                        let min = Math.min(...price);
                        let max = Math.max(...price);
                        if (min != max) {
                            model.displayPrice = `${min}+`;
                        } else if (min == max) {
                            model.displayPrice = `${min}`;
                        }
                    } else {
                        model.displayPrice = "N/A";
                    }
                    models[model.id] = model;
                }
            });
			console.log('MODELS', models)
            return models;
        },
        units() {
            let units = {};
            Object.values(this.$store.state.inventory.units).forEach((unit) => {
                if (unit.buildingType == "models") {
                    units[unit.id] = unit;
                }
            });
            return units;
        },
        lotsByPremiums() {
            return this.$store.getters.lotsByPremiums;
        },
		premiums(){
			return this.$store.state.inventory.premiums;
		},
		allPremiums(){
			let premiums = []
			Object.values(this.premiums).forEach(x => {
				premiums.push(x.id)
			})
			return premiums
		},
        users() {
            return this.$store.state.inventory.users;
        },

        brickChosen() {
            if (!this.status.brick) return false;
            return this.bricks.find((x) => x.id === this.status.brick);
        },

		appOptions() {
            return (
                (this.$store.state.inventory.allSettings &&
                    this.$store.state.inventory.allSettings.userApp &&
                    this.$store.state.inventory.allSettings.userApp.options) ||
                {}
            );
        },
		instance(){
			return this.$store.state.instance
		},
		siteplan(){
			return this.$store.state.inventory.siteplan
		},

		lotOverview() {
			let data = [
				{
					label: "Total Lots",
					value: "total",
					color: "#2B93C6",
					select: "Select All",
				},
				{
					label: "Lots Available",
					value: "available",
					color: "#1EC48C",
					select: "Available",
				},
				{
					label: "Lots Sold",
					value: "sold",
					color: "#FD6C83",
					select: "Sold",
				},
				{
					label: "Lots On Hold",
					value: "hold",
					color: "#FECE5B",
					select: "Hold",
				},
				{
					label: "Sold Conditional",
					value: "conditional",
					color: "#9693E8",
					select: "Sold Conditional",
				},
				{
					label: "Standing Inventory",
					value: "inventory",
					color: "#3395c6",
					select: "Standing Inventory",
				},
				{
					label: "Not Released",
					value: "notreleased",
					color: "#3F3356",
					select: "Not Released",
				}
			]

			if (this.isReservationOrWorksheetActive) {
				data.push(...[
					{
						label: "Units Allocated",
						value: "allocated",
						color: "var(--primary)",
						select: "Allocated",
					},
					{
						label: "Units Approved",
						value: "approved_for_reservation",
						color: "#134F5C",
						select: "Approved",
					},
					{
						label: "Lots Reserved",
						value: "reserved",
						color: "#419E00",
						select: "Reserved",
					},
				])
			}

			return data
		},

		statusOptions() {
			let options = [
				{ value: "available", label: "Available" },
				{ value: "sold", label: "Sold" },
				{ value: "hold", label: "Hold" },
				{ value: "conditional", label: "Conditional" },
				{ value: "inventory", label: "Standing Inventory" },
				{ value: "notreleased", label: "Not Released" },
			]

			if (this.isReservationOrWorksheetActive) {
				options.push(...[
					{ value: "allocated", label: "Allocated" },
					{ value: "approved_for_reservation", label: "Approved" },
					{ value: "reserved", label: "Reserved" },
				])
			}

			return options;
		},

        isReservationOrWorksheetActive() {
            return (this.$store.getters.hasReservationAccess && this.$store.getters.isReservationActive) || (this.$store.getters.hasWorksheetAppAccess && this.$store.getters.isWorksheetActive)
        }
    },
    data() {
        return {
			showFilterExpanded: false,
            bulkActions: [
                {
                    label: "Bulk Apply Tags",
                    value: "tag",
                },
				{
					label: "Bulk Update Status",
					value: "status",
				},
				{
					label: "Bulk Update Dates",
					value: "date",
				}
            ],
            showTMInfoTip: false,
            doNotShowTMInfoTipAgain: false,
			selectedUpdateStatusLot: {},
            showBulkTags: false,
			showBulkStatus: false,
			showCloseDateUpdate: false,
			closingDate: null,
            selectLot: [],
            selectType: ["total"],
            modal: {
                visible: false,
                type: "",
                object: {},
            },
            status: {
                status: "",
                elevation: "",
                package: "",
                price: 0,
                owner: "",
                purchasers: [],
                brick: "",
            },
            bricks: [],
			currentWidth: window.innerWidth,
			showing:{
                filter:false,
                plan:'',
                lot:'',
            },
            showingLot:{},
			loading:false,
			loadedSiteplan:false,
			selectedGroup:'all',
			selectedElev:'',
			showElevationDrawer:true,
			allFilter1:[],
			resizeObserver: null,
			salesStatus: {
				available:'Available',
				inventory:'Inventory',
				sold:'Sold',
				conditional:'Conditional',
				soldConditional:'Sold conditional',
				hold:'Hold',
				notreleased:'Not released',
				allocated: "Allocated",
				approved_for_reservation: "Approved",
				reserved: "Reserved"
			},
			statusColorCode: {
				available:'#1ec48c',
				inventory:'#3395c6',
				sold:'#fd6c83',
				conditional:'#9693e8',
				soldConditional:'#9693e8',
				hold:'#fece5b',
				notreleased:'#3f3356',
				allocated: "var(--primary)",
				approved_for_reservation: "#134F5C",
				reserved: "#419E00"
			}
        };
    },
    methods: {
		async showUnit(id) {
            let units = [];
            this.$store.commit("LOAD", true);
			console.log()
            if (!this.availableUnits[this.showing.lot]) {
                await this.$api
                    .get(
                        `/architectural-controls/:instance/availableunits/${this.showing.lot}`
                    )
                    .then(({ data }) => {
                        units = data.units.map((x) => {
                            if (typeof x === "object" && x.id) return x.id;
                            return x;
                        });
                        this.$store.commit("UPDATE_AVAIL_UNITS", {
                            id: this.showing.lot,
                            units: units,
                        });
                    })
                    .catch((err) => {
                        this.$message.error(
                            "An error occurred. Please try again"
                        );
                        console.error(err);
                    })
                    .finally(() => {
                        this.$store.commit("LOAD", false);
                    });
            } else {
                units = this.availableUnits[this.showing.lot];
            }
            this.$store.commit("OPEN_HOME_DETAILS", {
                units: units,
                unit: id,
                lot: this.showing.lot,
            });
            this.$store.commit("LOAD", false);
        },
		resetLot(){
			this.showing.lot = '';
		},
		toggleElevation(){
			console.log('Toggle Elevation Drawer')
			this.showElevationDrawer = !this.showElevationDrawer
		},
		handleCanvasWidthChange() {
			console.log("CANVAS WIDTH CHANGED")
			if (this.currentView == 'map' && this.mysp){
				this.mysp.refreshInit()
			}
		},
		async showElevation(elev){
			console.log('SHOW ELEVATION', elev)
			this.loading = true
			this.selectedElev = elev.id
			if(!this.mysp){
				return;
			}
			let lots = await this.mysp.filterLotsByUnit(this.selectedElev)
			console.log('FILTERED LOTSSSS', lots)
			if (lots && lots.length){
				this.mysp.highlightLots(lots)
			} else {
				this.$message.error('This model is not available on any lot.')
				this.mysp.highlightLots(lots)
			}
			this.loading = false
		},
		clearElevation(){
			console.log('Clearing elevation...')
			this.$message.success('Elevation successfully cleared!')
			this.selectedElev = ''
			this.mysp.highlightLots([]);
		},
		zoom(is){
			if (!is) return
			if (is === 'out') {
				let zoom = (this.mysp && this.mysp.camera && this.mysp.camera.zoom) - 0.4
				if (zoom <= 1.04 ) this.mysp.zoomReset()
				else this.mysp.zoomOut()
			}
			if (is === 'in') this.mysp.zoomIn()
		},
		async onViewChange(view){
			console.log('Changing view...', view)
			if (this.currentView != 'map' && view == 'map'){
				this.$store.commit("LOAD", true);
				this.selectedElev = ''
				this.selectedGroup = 'all'
				console.log('Loading siteplan...')
				let dom = document.querySelector('.siteplan-holder')
				console.log('SPDATA SITEPLAN', this.siteplan)
				this.mysp = initSp({
					dom,
					spData:this.siteplan,
					onEvent: this.onEvent,
					onFilter: this.onFilter,
					hideHold:false,
				})

				let initObj = {
					instance:this.instance.id,
					selectedStatuses:this.selectType,
				}
				console.log('Beginnning init...')
				await this.mysp.init(initObj)

				window.mysp = this.mysp
				this.loadedSiteplan = true
				this.$store.commit("LOAD", false);
			}
			this.$store.commit("UPDATE_INVENTORY_VIEW", view)
		},
		onFilter(e){
			if (!e.dataset.value){
				console.log('SELECTED ALL')
				this.selectedGroup = 'all'
			} else {
				console.log('SELECTED GROUP', e.dataset.value)
				let name = e.dataset.value
				let obj = {
					name:name,
					size:'',
					type:''
				}
				if (name.includes("' ")){
					let arr = name.split("' ")
					console.log('arr', arr)
					obj.size = parseInt(arr[0])
					obj.type = arr[1]
				}
				console.log('OBJ', obj)
				this.selectedGroup = obj
			}
		},
        applyBulkTags(tags) {
            this.lots.forEach((x) => {
                if (this.selectLot.includes(x.id) && (!x.transactionId || x.status === "available")) {
                    tags.forEach((y) => {
                        if (!x.tags.includes(y)) {
                            x.tags.push(y);
                        }
                    });
                }
            });
        },
		applyBulkStatus(status) {
            this.lots.forEach((x) => {
                if (this.selectLot.includes(x.id) && (!x.transactionId || x.status === "available" || x.status === 'notreleased')) {
                    x.status = status;
                }
            });
        },
        closeBulkTags() {
            this.showBulkTags = false;
        },
		closeBulkStatus() {
            this.showBulkStatus = false;
        },
		closeBulkCloseDate() {
			this.showCloseDateUpdate = false;
		},
		updateClosingDate(date) {
			const lots = this.$store.state.inventory.lots
			this.selectLot.forEach(id => {
				if (lots[id].status === 'available') {
					this.$store.commit("SET_PROP", {
						where: ["lots", id],
						what: { ...lots[id], closingDate: date },
					});
				}
			})
		},
		async bulkActioned({ key }) {
			if (key === "tag") {
				this.showBulkTags = true;
			} else if (key === "status") {
				this.showBulkStatus = true;
			} else if (key === "date") {
				if (this.$p < 20) {
					return this.$message.error('You do not have permission to update the close date.')
				}
				const lots = this.$store.state.inventory.lots
				this.selectLot = this.selectLot.filter(id => lots[id].status == 'available')
				if (this.selectLot.length) {
					this.showCloseDateUpdate = true
				} else {
					this.$message.error('Please select available lots to update close date.')
				}
			}
		},
		updateCloseDate(lot) {
			if (this.$p >= 20) {
				this.selectLot = [lot.id];
				this.showCloseDateUpdate = true;
				this.closingDate = lot.closingDate;
			} else {
				return this.$message.error("You do not have permission to update the close date");
			}
		},
        toggleSelect(id) {
            if (this.selectLot.includes(id)) {
                let index = this.selectLot.findIndex((x) => x == id);
                this.selectLot.splice(index, 1);
            } else {
                this.selectLot.push(id);
            }
        },
		makeTransaction(lot, { unit = null, selectedPackage = null }) {
			window.open(`https://transactions.bildhive.${this.$tld}/new?lot=${lot.id}&elevation=${unit}&package=${selectedPackage}`, '_blank');
		},
		createWorksheet(lot) {
			window.open(`https://worksheets.bildhive.${this.$tld}/create?lot=${lot.id}`, '_blank');
		},
        changeSelect(type, e) {
            if (this.selectType[0] == "total") this.selectType = [];
            if (type != "total") {
                if (this.selectType.includes(type)) {
                    let index = this.selectType.findIndex((x) => x == type);
                    this.selectType.splice(index, 1);
                } else {
                    this.selectType.push(type);
                }
            } else {
                this.selectType = ["total"];
            }
            if (this.selectType.length == 0 || this.selectType.length == 6)
                this.selectType = ["total"];

			if (this.currentView == 'map' && this.mysp){
				this.mysp.filterByStatus(this.selectType)
			}
        },
        openFilter() {
            this.$store.commit("OPEN_FILTER");
        },
        unsellLot(id) {
            let self = this;
            this.$confirm({
                title: "Unsell Lot",
                content: (h) => <div>Do you want to unsell this lot?</div>,
                okText: "Unsell",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    let object = {
                        lot: id,
                        status: "available",
                    };
                    self.$api
                        .put(`/sales/:instance/lr`, object)
                        .then(({ data }) => {
                            self.$store.commit("UPDATE_LOTS", data);
                            self.onClose();
                        })
                        .catch((err) => {
                            self.$message.error(
                                "An error occurred. Please try again"
                            );
                            console.error(err);
                        });
                },
            });
        },
        changeElevation(e) {
            this.status.elevation = e;
            if (this.units[e].packages.length) {
                this.status.package = this.units[e].packages[0].id;
                this.status.price = this.units[e].packages[0].price;
            }
            let premiumPrice = 0;
            Object.values(this.modal.object.other.premiums).forEach((x) => {
                if (x.active) {
                    premiumPrice += x.price;
                }
            });
            this.status.price += premiumPrice;
        },
        changePackage(e) {
            let index = this.units[this.status.elevation].packages.findIndex(
                (x) => x.id == e
            );
            this.status.price =
                this.units[this.status.elevation].packages[index].price;
            let premiumPrice = 0;
            Object.values(this.modal.object.other.premiums).forEach((x) => {
                if (x.active) {
                    premiumPrice += x.price;
                }
            });
            this.status.price += premiumPrice;
        },
        changeStatus(e) {
            this.status = {
                status: e,
                elevation: "",
                package: "",
                price: 0,
                brick: "",
            };
        },

        submit() {
            if (this.modal.type == "updateStatus") {
                this.$refs.status.validate((valid) => {
                    if (valid) {
                        this.$store.commit("LOAD", true);
                        let object = {};
                        if (
                            this.status.status == "sold" ||
                            this.status.status == "conditional" || this.status.status == 'soldConditional'
                        ) {
                            object = {
                                lot: this.modal.object.id,
                                unit: this.status.elevation,
                                unitGroup:
                                    this.units[this.status.elevation].unitGroup
                                        .id,
                                packageId: this.status.package,
                                finalPrice: this.status.price,
                                status: this.status.status,
                                soldBy: this.status.owner,
                                purchasers: this.status.purchasers.map((p) => {
                                    return { fullName: p };
                                }),
                                offer: {},
                            };

                            if (this.brickChosen) {
                                object.offer.bricks = [];
                                object.offer.bricks.push(this.brickChosen);
                            }
                        } else if (
                            this.status.status == "available" ||
                            this.status.status == "notreleased"
                        ) {
                            object = {
                                lot: this.modal.object.id,
                                status: this.status.status,
                            };
                        } else if (this.status.status == "inventory") {
                            object = {
                                lot: this.modal.object.id,
                                status: this.status.status,
                                unit: this.status.elevation,
                                packageId: this.status.package,
                                unitGroup:
                                    this.units[this.status.elevation].unitGroup
                                        .id,
                            };
                        } else if (this.status.status == "hold") {
                            object = {
                                lot: this.modal.object.id,
                                status: this.status.status,
                                unit: this.status.elevation,
                            };
                        }

                        this.$api
                            .put(`/sales/:instance/lr`, object)
                            .then(({ data }) => {
                                this.$store.commit("UPDATE_LOTS", data);
                                this.onClose();
                            })
                            .catch((err) => {
                                this.$message.error(
                                    "An error occurred. Please try again"
                                );
                                console.error(err);
                            })
                            .finally(() => {
                                this.$store.commit("LOAD", false);
                            });
                    }
                });
            } else if (this.modal.type == "makeTransaction") {
                this.$refs.transaction.validate((valid) => {
                    if (valid) {
                        this.onClose();
                    }
                });
            }
        },
        req: (msg) => ({ required: true, message: msg }),
        openModal(id, type) {
            this.applyingTags = [];

            let lot = JSON.parse(JSON.stringify(this.storeLots[id]));

            this.$store.commit("LOAD", true);

            this.$api
                .get(`/architectural-controls/:instance/availableunits/${id}`)
                .then(({ data }) => {
                    data.units = data.units.map((x) => {
                        if (typeof x === "object" && x.id) return x.id;
                        return x;
                    });
                    data.attachedUnit =
                        data.attachedUnit && data.attachedUnit.id
                            ? data.attachedUnit.id
                            : data.attachedUnit || "";
                    data.siteplan = data.siteplan.id
                        ? data.siteplan.id
                        : data.siteplan;

                    if (lot.status !== data.status) {
                        this.$store.commit("SET_PROP", {
                            where: ["lots", data.id],
                            what: data,
                        });

                        let message = `Lot ${data.name} has been updated externally!`;
                        if (data.status === "sold")
                            message = `Lot ${data.name} has been sold already!`;

                        return this.$notification.error({
                            message,
                            description:
                                "This lot was externally updated by another user. Please review and try again.",
                        });
                    }

                    if (data.isArchitecturalRulesEnabled) {
                        this.bricks = [{ id: "", name: "N/A" }, ...data.bricks];
                    }

                    lot = JSON.parse(JSON.stringify(data));

                    this.modal.type = type;

                    this.modal.object = lot;

                    this.status.status = lot.status;
                    let unit = "";
                    let packageId = "";

                    if (
                        lot.attachedUnit != null &&
                        lot.attachedUnit &&
                        typeof lot.attachedUnit == "string"
                    )
                        unit = lot.attachedUnit;
                    else if (
                        lot.attachedUnit != null &&
                        lot.attachedUnit &&
                        typeof lot.attachedUnit == "object"
                    )
                        unit = lot.attachedUnit.id;
                    if (
                        (lot.status == "hold" ||
                            lot.status == "sold" ||
                            lot.status == "conditional" || lot.status == 'soldConditional' ||
                            lot.status == "inventory") &&
                        lot.attachedUnit &&
                        lot.attachedUnit != null
                    )
                        this.status.elevation = unit;

                    if (
                        lot.attachedPackage != null &&
                        lot.attachedPackage &&
                        typeof lot.attachedPackage == "string"
                    )
                        packageId = lot.attachedPackage;
                    else if (
                        lot.attachedPackage != null &&
                        lot.attachedPackage &&
                        typeof lot.attachedPackage == "object"
                    )
                        packageId = lot.attachedPackage.id;
                    if (
                        (lot.status == "sold" ||
                            lot.status == "inventory" ||
                            lot.status == "conditional" || lot.status == 'soldConditional') &&
                        lot.attachedPackage &&
                        lot.attachedPackage != null
                    )
                        this.status.package = packageId;
                    this.applyingTags = lot.tags.map((x) => (x.id ? x.id : x));
                    this.modal.visible = true;
					this.selectedUpdateStatusLot = {};
                })
                .catch((err) => {
                    this.$message.error("An error occurred. Please try again");
                    console.error(err);
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        applyTags(id) {
            if (this.applyingTags.includes(id)) {
                this.applyingTags = this.applyingTags.filter((x) => x != id);
            } else {
                this.applyingTags.push(id);
            }
        },
        updateTags(lot, e) {
            let siteplanId = lot.siteplan.id ? lot.siteplan.id : lot.siteplan;
            this.$store.commit("LOAD", true);
            this.$api
                .put(`/lots/:instance/${siteplanId}/${lot.id}`, { tags: e })
                .then(({ data }) => {
                    data.units = data.units.map((x) => {
                        if (typeof x === "object" && x.id) return x.id;
                        return x;
                    });
                    data.tags = data.tags.map((x) => {
                        if (typeof x === "object" && x.id) return x.id;
                        return x;
                    });
                    data.attachedUnit =
                        data.attachedUnit && data.attachedUnit.id
                            ? data.attachedUnit.id
                            : data.attachedUnit || "";
                    data.siteplan = data.siteplan.id
                        ? data.siteplan.id
                        : data.siteplan;

                    this.$store.commit("SET_PROP", {
                        where: ["lots", data.id],
                        what: data,
                    });
                    this.$message.success("Tags updated successfully!");
                })
                .catch((err) => {
                    this.$message.error(
                        "Could not apply Tags. Please try again"
                    );
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },
        onClose() {
            if (this.modal.type == "updateStatus")
                this.$refs.status.resetFields();
            else if (this.modal.type == "makeTransaction")
                this.$refs.transaction.resetFields();
            this.modal.visible = false;
            this.modal.type = "";
            this.modal.object = {};
            this.status = {
                status: "",
                elevation: "",
                package: "",
                price: 0,
                brick: "",
            };
            this.transaction = {
                status: "",
                elevation: "",
                price: 0,
            };
        },
        calculatePrice(id) {
            let price = 0;
            let more = [];
            this.storeLots[id].units.forEach((unit) => {
                let modelPrice = "";
                let unitId = "";
                if (typeof unit == "string") unitId = unit;
                else if (typeof unit == "object") unitId = unit.id;
                if (!this.models[unitId]) modelPrice = 0;
                else {
                    if (
                        this.models[unitId] &&
                        this.models[unitId].displayPrice &&
                        this.models[unitId].displayPrice.includes("+")
                    ) {
                        modelPrice = this.models[unitId].displayPrice.substring(
                            0,
                            this.models[unitId].displayPrice.length - 1
                        );
                        modelPrice = parseFloat(modelPrice);
                        more.push(modelPrice);
                    } else {
                        modelPrice = parseFloat(
                            this.models[unitId].displayPrice
                        );
                    }
                    if (this.models[unitId].displayPrice == "N/A") {
                        modelPrice = 0;
                    }
                }
                price = price + modelPrice;
            });
            if (price == 0) price = "N/A";
            if (more.length) price = `${price.toLocaleString("us-en")}+`;
            return price;
        },
        getModelType(id) {
            let types = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;

                    if (
                        this.units[id] &&
                        !types.includes(this.units[id].unitGroup.type)
                    ) {
                        types.push(this.units[id].unitGroup.type);
                    }
                });
                if (types.length > 1) {
                    types = "Various";
                } else if (types.length == 1) {
                    types = types[0];
                }
            } else {
                return (types = "N/A");
            }
            return types;
        },
        getFrontage(id) {
            let frontage = [];
            if (!this.storeLots[id].units.length) {
                return "N/A";
            } else {
                this.storeLots[id].units.forEach((unit) => {
                    let id = "";
                    if (typeof unit == "string") id = unit;
                    else if (typeof unit == "object") id = unit.id;
                    if (
                        this.units[id] &&
                        !frontage.includes(this.units[id].unitGroup.size)
                    ) {
                        frontage.push(this.units[id].unitGroup.size);
                    }
                });
                if (frontage.length == 1) {
                    return frontage[0];
                } else if (frontage.length > 1) {
                    let min = Math.min(...frontage);
                    let max = Math.max(...frontage);
                    return `${min}-${max}`;
                }
            }
        },
        getUnits(id) {
            let units = [];
            if (
                this.storeLots[id] &&
                this.storeLots[id].units &&
                this.storeLots[id].units.length
            ) {
                this.storeLots[id].units.forEach((unit) => {
                    if (this.units[unit]) {
                        units.push(
                            `${this.units[unit].unitGroup.name} - ${this.units[unit].name}`
                        );
                    }
                });
            }
            return units;
        },
        getPremium(id) {
            let price = 0;
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return 0;
            else {
                premiums.forEach((x) => {
                    price = price + x.price;
                });
                return price;
            }
        },
        getPremiums(id) {
            let premiums =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.premiums) ||
                {};
            premiums = Object.values(premiums).filter((x) => x.active);

            if (premiums.length == 0) return "";
            return premiums.map((x) => {
                return `${x.name} - $${x.price.toLocaleString("us-en")}`;
            });
        },
        getGradings(id) {
            let gradings =
                (this.storeLots[id].other &&
                    this.storeLots[id].other.gradings) ||
                {};
            gradings = Object.values(gradings).filter((x) => x.active);

            if (gradings.length == 0) return "";
            return gradings.map((x) => {
                return `${x.name} - $${
                    x.price ? x.price.toLocaleString("us-en") : 0
                }`;
            });
        },
        getCondition(id) {
            let conditions = {
                none: "None",
                sideRearUpgrade: "Side and Rear Upgrade",
                wob: "Walk-out Basement",
                rearUpgrade: "Rear Upgrade",
                other: "Other",
                cornerUpgrade: "Corner Upgrade",
            };
            let conds = [];
            let lot = this.storeLots[id];
            let other = null;
            if (typeof lot.condition == "string") {
                return [conditions[lot.condition]];
            } else {
                lot.condition.forEach((x) => {
                    if (x === "other" && lot.notes && lot.notes.length) {
                        other = `Other: ${lot.notes}`;
                    } else if (x != "none") {
                        conds.push(conditions[x]);
                    }
                });

                if (other) conds.push(other);
                return conds;
            }
        },

		updateStatus(lot) {
            if (
                (this.$p === 10 && lot.status != "notreleased") ||
                this.$p >= 20
            ) {
                // show TM user info modal
                if (!this.doNotShowTMInfoTipAgain) {
                    this.selectedUpdateStatusLot = lot;
                    this.showTMInfoTip = true;
                } else {
                    this.openModal(lot.id, "updateStatus");
                }
            } else
                return this.$message.error(
                    "You do not have permission to update the status"
                );
        },

		disabledDate(current) {
            return current && current < moment().startOf('day')
        },

		tmInfoUpdate() {
            this.showTMInfoTip = false;
            if (this.doNotShowTMInfoTipAgain) {
                const options = {
                    seenIntro: true,
                    ...this.userAppOptions,
                    doNotShowTMInfoTipAgain: this.doNotShowTMInfoTipAgain,
                };
                this.$api.post(`/settings/:instance/inventory/user`, {
                    options,
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
                const allSettings = this.$store.state.inventory.allSettings;
				if(!allSettings.userApp){
					allSettings.userApp = {
						options: options
					}
				} else {
					allSettings.userApp.options = options;
				}
                this.$store.commit("SET_SETTINGS", allSettings);
            }
            if (this.selectedUpdateStatusLot !== {}) {
                this.openModal(
                    this.selectedUpdateStatusLot.id,
                    "updateStatus"
                );
            }
        },

		updateWidth(){
			this.currentWidth = window.innerWidth;
		},
		async onEvent(action,data){
			if (action === 'SHOW_LOT' && data && data.id){
				console.log('SHOWING LOT', data )
				this.loading = true
				let apiURL = `${window.location.href.includes("com") ? 'https://api.bildhive.com' : 'https://api.bildhive.dev'}`
				await fetch(`${apiURL}/v1/lot/available/${data.id}?token=${this.instance.id}`).then((response) => {
					if (response.ok){
						return response.json()
					}
					throw new Error('Something went wrong');
				}).then((responseJson) => {
					let rUnits = responseJson.units.map(x => x = x.id)
					data.units = data.units.filter(x => rUnits.includes(x.id))
					this.loading = false
					this.showing.lot = data.id
					this.showingLot = data
					console.log('Showing lot...', this.showingLot)
				}).catch((error) => {
					console.log('error', error)
					this.loading = false
					this.showing.lot = data.id
					this.showingLot = data
				})
				return
			} else if (action === 'TAP'){
				console.log('JUST TAP')
				this.showing.lot = ''

			}
		},
    },
    created() {
		this.$store.commit('UPDATE_INVENTORY_VIEW', "grid")
        this.$store.commit("UPDATE_CRUMBS", "inventory");
		if (this.appOptions && this.appOptions.doNotShowTMInfoTipAgain) {
            this.doNotShowTMInfoTipAgain = true;
        }
    },
	async mounted() {
		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateWidth);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateWidth)
		let resizeTimer;
		this.resizeObserver = new ResizeObserver(entries => {
			window.requestAnimationFrame(() => {
				for (let entry of entries) {
					if (entry.target === this.$refs.canvas) {
						clearTimeout(resizeTimer);
						resizeTimer = setTimeout(() => {
							this.handleCanvasWidthChange();
						}, 150);
					}
				}
			})
		});
		if(this.$refs.canvas){
			this.resizeObserver.observe(this.$refs.canvas);
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateWidth)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
        }
	},
};
</script>

<style scoped lang="scss">
.clear-button {
	margin-bottom: 0;
}
.clear-button:hover {
    cursor: pointer;
    text-decoration: underline;
}
.filter-dropdown {
    border: 1px solid #aaa;
    padding: 10px;
    background: #eee;
    box-shadow: 0 2px 5px rgba(100,100,100,0.3);
}
.filter-dropdown-expanded {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 1rem;
	border: 1px solid #aaa;
    padding: 10px;
    background: #eee;
    box-shadow: 0 2px 5px rgba(100,100,100,0.3);
}
.unit-list {
	width: 0px;
	transition: width 0.3s ease-in-out;
}
.unit-list.active {
	width: 350px;
	transition: width 0.3s ease-in-out;
}
.siteplan-holder {
	transition: padding-bottom 0.3s ease-in-out, height 0.3s ease-in-out;
}
.select-elevation {
	height: 55px;
	transition: height 0.3s ease-in-out;
}
.select-elevation.active {
	height: 245px;
	transition: height 0.3s ease-in-out;
}
.siteplan-tooltip-style1{
    pointer-events: none;

}
.siteplan-tooltip-style1 div{
    padding: .25rem .5rem;
    border-radius:.75rem;
    font-size:12px;
    position: relative;
    top:-15px;
	color: #FFF;
	border: 2px solid white;
}
.overview-card {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #e6eaee;
    background-color: #fff;
}
// a css class that animates by pulsating the border once
.pulse {
    position: relative;
    &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        animation: pulse 1s ease-in-out;
        animation-iteration-count: 3;
    }
}
@keyframes pulse {
    0% {
        background-color: rgba(255, 255, 255, 0);
    }
    50% {
        background-color: rgba(255, 0, 255, 1);
    }
    100% {
        background-color: rgba(255, 0, 255, 0);
    }
}
@media screen and (max-width: 750px) {
    .last-item {
        grid-column: 1 / span 2;
    }
}
.mid-flex {
    @media screen and (min-width: 1024px) {
        display: flex;
    }
}
.grid-display-4 {
    display: grid;
    @media screen and (min-width: 576px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
.grid-display-2 {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 1100px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.gap-10 {
    gap: 2.5rem;
}
.gap-5 {
    gap: 1.25rem;
}
.popover-content {
    padding: 3px;
    cursor: pointer;
}
.popover-content:hover {
    background-color: var(--off-white-dark);
}
</style>
<style>
.model-type {
	border: 1px solid #9EA0A5;
	transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.model-type:hover {
	border: 1px solid #F79425 !important;
    color: #F79425 !important;
	transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.model-type.active {
	border: 1px solid #F79425 !important;
    color: #F79425 !important;
	transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
}
.no-padding .ant-popover-inner-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 170px;
}
</style>
