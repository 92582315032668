<template>
	<div>
		<LeftNavBar :title="selectedAppInitial" :hasChild="false" :items="sidebarItems" @opened="selectFormType"
			:selectedValue="crumbs" />
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
	components: { LeftNavBar },
	name: "menu-left",
	data() {
		return {
			openID: "",
		};
	},
	computed: {
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"IPM"
			);
		},
		sidebarItems() {
			return this.$store.getters.sidebarItems;
		},
		crumbs() {
			return this.$store.state.inventory.crumbs;
		},
	},
	methods: {
		selectFormType(data) {
			if(data.value === 'inventory') {
				if (this.$route.path != "/") this.$router.push("/");
				this.$store.commit("UPDATE_CRUMBS", "inventory");
			} else {
				if (!this.$route.path.includes(data.value)) this.$router.push(data.value);
				this.$store.commit("UPDATE_CRUMBS", data.value);
			}
		},
		editLot(lot) {
			this.$store.commit("SHOW_EDIT_LOT", lot);
		},
		handleClick(e) {
			this.$store.commit("changeFolder", e.key);
		},
	},
};
</script>

<style>
.catTitle>div>span {
	white-space: break-spaces !important;
	line-height: normal !important;
}
</style>
