<template>
    <div
        class="w-full dF fC f1 px-4 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
    >
        <h3>Price History</h3>
        <template>
            <div v-if="instance.productType === 'lowrise'">
                <a-tabs
                    default-active-key="lots"
                    size="large"
                    v-model="historyTab"
                >
					<div slot="tabBarExtraContent">
						<a-input placeholder="Search here..." v-model="search" style="width: 300px;"></a-input>
					</div>
                    <a-tab-pane key="lots" tab="Lot Premium" />
                    <a-tab-pane key="elevation" tab="Elevation Price" />
                </a-tabs>
            </div>
            <div v-else>
                <a-tabs
                    default-active-key="units"
                    size="large"
                    v-model="historyTab"
                >
					<div slot="tabBarExtraContent">
						<a-input placeholder="Search here..." v-model="search" style="width: 300px;"></a-input>
					</div>
                    <a-tab-pane key="units" tab="Units"> </a-tab-pane>
                    <a-tab-pane key="premiums" tab="Premiums"> </a-tab-pane>
                    <a-tab-pane key="addOns" tab="Add Ons">
                        <div class="dF aC">
                            <a-button
                                :type="addonTab === 'parking' ? 'primary' : ''"
                                @click="addonTab = 'parking'"
                                >Parking Spots</a-button
                            >
                            <a-button
                                class="ml-4"
                                :type="addonTab === 'lockers' ? 'primary' : ''"
                                @click="addonTab = 'lockers'"
                                >Lockers</a-button
                            >
                            <a-button
                                class="ml-4"
                                :type="
                                    addonTab === 'bikeRacks' ? 'primary' : ''
                                "
                                @click="addonTab = 'bikeRacks'"
                                >Bike Racks</a-button
                            >
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>

            <a-table
                :rowKey="(e) => e.id"
                class="white-table"
                :class="
                    historyTab !== 'addOns' &&
                    instance.productType !== 'lowrise'
                        ? 'mt-5'
                        : 'mt-3'
                "
                :columns="priceHistoryColumns"
                :data-source="priceHistoryRecords"
				:scroll="{ x: 1100 }"
            >
                <div slot="date" slot-scope="obj" style="color: #9ea0a5">
                    {{ $formatDate(obj.createdAt, 'lll') }}
                </div>
                <div slot="item" slot-scope="obj">
                    {{ obj.itemName }}
                </div>
				<div slot="premiums" slot-scope="obj" v-if="historyTab == 'lots'">
					<div v-for="(prem,premI) in obj.premiums" :key="premI">{{ prem }}</div>
				</div>
                <div slot="user" slot-scope="obj">
                    {{ `${obj.user.firstName} ${obj.user.lastName}` }}
                </div>
                <div slot="priceBefore" slot-scope="obj">
                    <template v-if="historyTab == 'lots' && instance.productType == 'lowrise'"><div v-for="(prem,premI) in obj.priceBefore" :key="premI">${{ prem && prem.toLocaleString() }}</div></template>
					<template v-else>{{ `$${obj.priceBefore && obj.priceBefore.toLocaleString()}` }}</template>
                </div>
                <div slot="priceAfter" slot-scope="obj">
					<template v-if="historyTab == 'lots' && instance.productType == 'lowrise'"><div v-for="(prem,premI) in obj.priceAfter" :key="premI">${{ prem && prem.toLocaleString() }}</div></template>
					<template v-else>{{ `$${obj.priceAfter && obj.priceAfter.toLocaleString()}` }}</template>
                </div>
                <div slot="adjustment" slot-scope="obj">
					<template v-if="historyTab == 'lots' && instance.productType == 'lowrise'">
						<div v-for="(price,priceI) in obj.priceBefore" :key="priceI">{{
							`$${getAdjustment(
								obj.priceBefore[priceI],
								obj.priceAfter[priceI]
							).toLocaleString()} (${getPercentage(
								obj.priceBefore[priceI],
								obj.priceAfter[priceI]
							)}%)`
						}}</div>
					</template>
					<template v-else>{{
                        `$${getAdjustment(
                            obj.priceBefore,
                            obj.priceAfter
                        ).toLocaleString()} (${getPercentage(
                            obj.priceBefore,
                            obj.priceAfter
                        )}%)`
                    }}</template>
                </div>
				<div slot="action">
					<a :href="`https://reports.bildhive.${$tld}/inventory?tab=${this.historyTab}`" target="_blank"><a-button type="primary">View Report</a-button></a>
				</div>
            </a-table>
        </template>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
			search: '',
            historyTab: "lots",
            addonTab: "parking",
            lotTimelines: [],
            elevationTimelines: [],
            unitTimelines: [],
            premiumTimelines: [],
            parkingTimelines: [],
            lockerTimelines: [],
            bikeRackTimelines: [],
			resizeObserver: null
        };
    },
    computed: {
        priceHistoryColumns() {
            const columns = [
                {
                    title: "Item #",
                    key: "Item",
                    scopedSlots: { customRender: "item" },
                    sorter: (a, b) => a.itemName.localeCompare(b.itemName),
                },
                {
                    title: "Date",
                    key: "date",
                    scopedSlots: { customRender: "date" },
                    sorter: (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
                    defaultSortOrder: 'descend'
                },
                {
                    title: "Sales Rep.",
                    key: "Sales Rep.",
                    scopedSlots: { customRender: "user" },
                    sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
                },
                {
                    title: "Previous Price",
                    key: "Price Before",
                    scopedSlots: { customRender: "priceBefore" },
                    sorter: (a, b) => a.priceBefore - b.priceBefore,
                },
                {
                    title: "Adjustment",
                    key: "Adjustment",
                    scopedSlots: { customRender: "adjustment" },
                    sorter: (a, b) => (a.priceAfter - a.priceBefore) - (b.priceAfter - b.priceBefore),
                },
                {
                    title: "New Price",
                    key: "New Price",
                    scopedSlots: { customRender: "priceAfter" },
                    sorter: (a, b) => a.priceAfter - b.priceAfter,
                },
                {
                    key: 'action',
                    scopedSlots: { customRender: 'action' }
                }
            ];

            if (this.historyTab === "lots") {
                columns.splice(2, 0, {
                    title: "Premiums",
                    key: "premiums",
                    scopedSlots: { customRender: "premiums" }
                });
                columns[0].title = "Lot #";
            } else if (this.historyTab === "elevation") {
                columns[0].title = "Elevation";
            }

            return columns;
        },
        dateFormat() {
            return this.$store.state.inventory.allSettings.user?.options?.regional?.dateFormat || 'MM/DD/YYYY';
        },
        lots() {
            return this.$store.state.inventory.lots;
        },
        units() {
            return this.$store.state.inventory.units;
        },
        instance() {
            return this.$store.state.instance;
        },
        storedUnits() {
            let units = {};
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.unitNumber) < parseInt(b.unitNumber))
                        return -1;
                    if (parseInt(a.unitNumber) > parseInt(b.unitNumber))
                        return 1;
                    else return 0;
                });
                return array;
            };
            if (this.$store.state.instance.productType === "highrise") {
                orderAlpha(
                    Object.values(this.$store.state.inventory.units)
                ).forEach((unit) => {
                    if (unit.buildingType === "condounits") {
                        units[unit.id] = unit;
                    }
                });
            }
            return units;
        },
        condoPremiums() {
            return this.$store.state.inventory.condoPremiums;
        },
        condoUnitPremiums() {
            let units = Object.values(this.storedUnits);
            let premiums = JSON.parse(JSON.stringify(this.condoPremiums));

            units.forEach((x) => {
                for (var y in x.packages[0].other.premiums) {
                    let y2 = x.packages[0].other.premiums[y];
                    if (premiums.hasOwnProperty(y)) {
                        if (y2.active) {
                            premiums[y].units.push(x.id);
                        }
                    }
                }
            });
            return premiums;
        },
        condoAddons() {
            return this.$store.state.inventory.condoAddons || { parking: [], lockers: [], bikeRacks: [] };
        },
        parking() {
            const parkingData = {};
            this.condoAddons.parking.forEach((p) => {
                parkingData[p.id] = p;
            });
            return parkingData;
        },
        lockers() {
            const lockersData = {};
            this.condoAddons.lockers.forEach((l) => {
                lockersData[l.id] = l;
            });
            return lockersData;
        },
        bikeRacks() {
            const bikeRacksData = {};
            this.condoAddons.bikeRacks.forEach((b) => {
                bikeRacksData[b.id] = b;
            });
            return bikeRacksData;
        },

        priceHistoryRecords() {
            let records = [];
            if (this.historyTab === "lots") {
                records = this.lotTimelines;
            } else if (this.historyTab === "elevation") {
                records = this.elevationTimelines;
            } else if (this.historyTab === "units") {
                records = this.unitTimelines;
            } else if (this.historyTab === "premiums") {
                records = this.premiumTimelines;
            } else if (this.historyTab === "addOns") {
                if (this.addonTab === "parking") {
                    records = this.parkingTimelines;
                } else if (this.addonTab === "lockers") {
                    records = this.lockerTimelines;
                } else if (this.addonTab === "bikeRacks") {
                    records = this.bikeRackTimelines;
                }
            }

            return records.filter((record) => record.itemName.toLowerCase().includes(this.search.toLowerCase()));
        },

        condoAddonsLength() {
            return Object.keys(this.condoAddons).length;
        },
    },
    methods: {
        moment,
        getAdjustment(before, after) {
            return after - before;
        },
        getPercentage(before, after) {
            if (before === after || (before === 0 && after === 0)) return 0;
            return parseFloat(((after - before) / before) * 100).toFixed(3);
        },

        createHistoryData(data, timelines = "unitTimelines") {
            data.forEach((item) => {
                if (item.data && item.data.dataAfter && item.data.dataBefore) {
                    Object.keys(item.data.dataAfter).forEach((id) => {
                        let itemName = "";
                        const unitId = id.split("-")[0];
                        switch (timelines) {
                            case "unitTimelines":
                                itemName = `Unit ${this.units[unitId]?.unitNumber || ""}`;
                                break;
                            case "premiumTimelines":
                                itemName = this.condoUnitPremiums[id]?.name || "";
                                break;
                            case "parkingTimelines":
                                itemName = `Parking ${this.parking[id]?.name || ""}`;
                                break;
                            case "lockerTimelines":
                                itemName = `Locker ${this.lockers[id]?.name || ""}`;
                                break;
                            case "bikeRackTimelines":
                                itemName = `Bike Rack ${this.bikeRacks[id]?.name || ""}`;
                                break;
                        }
                        this[timelines].push({
                            id: id + item.id,
                            itemName,
                            unit: unitId,
                            createdAt: item.createdAt,
                            user: item.user,
                            priceBefore: item.data.dataBefore[id],
                            priceAfter: item.data.dataAfter[id],
                        });
                    });
                }
            });
        },

        fetchHistory() {
            this.$api
                .get(`/timelines/:instance/ipm`)
                .then(({ data }) => {
                    if (this.instance.productType === "lowrise") {
                        const lotHistory = data.filter(
                            (x) => x.data?.body?.type === "lots"
                        );

                        const unitsHistory = data.filter(
                            (x) => x.data?.body?.type === "units"
                        );

                        lotHistory.forEach((item) => {
                            if (
                                item.data?.dataAfter &&
                                item.data?.dataBefore
                            ) {
                                Object.keys(item.data.dataAfter).forEach(
                                    (id) => {
                                        let obj = {
                                            id: id + item.id,
                                            lot: id,
                                            itemName: `Lot ${this.lots[id]?.name || ''}${this.lots[id]?.block ? ` (Block ${this.lots[id].block})` : ''}`,
                                            createdAt: item.createdAt,
                                            user: item.user,
                                            priceBefore: [],
                                            priceAfter: [],
                                            premiums: []
                                        };

                                        const premiumsBefore = {};
                                        Object.values(item.data.dataBefore[id].premiums).forEach((prem) => {
                                            if (prem.active && prem.price) {
                                                premiumsBefore[prem.id] = prem;
                                                obj.priceBefore.push(prem.price);
                                            }
                                        });

                                        Object.values(item.data.dataAfter[id].premiums).forEach((prem) => {
                                            if (prem.active && prem.price) {
                                                obj.premiums.push(prem.name);
                                                obj.priceAfter.push(prem.price);
                                            }
                                        });
                                        let newPremiums = []
										let newPriceBefore = []
										let newPriceAfter = []
										for (let i = 0; i < obj.priceAfter.length; i++) {
											if(obj.priceBefore[i] !== obj.priceAfter[i]){
												newPremiums.push(obj.premiums[i])
												newPriceBefore.push(obj.priceBefore[i])
												newPriceAfter.push(obj.priceAfter[i])
											}
										}
										obj.premiums = newPremiums
										obj.priceBefore = newPriceBefore
										obj.priceAfter = newPriceAfter
                                        this.lotTimelines.push(obj);
                                    }
                                );
                            }
                        });

                        unitsHistory.forEach((item) => {
                            if (
                                item.data?.dataAfter &&
                                item.data?.dataBefore
                            ) {
                                Object.keys(item.data.dataAfter).forEach(
                                    (id) => {
                                        const [unitId, packageId, ...rest] = id.split("-");
                                        const unit = this.units[unitId];

                                        if (unit) {
                                            const elevationName = `${unit.unitGroup.name} ${unit.name}`;
                                            const pkgId = rest.length ? rest.join("-") : packageId;
                                            const pkg = unit.packages.find(p => p.uid === pkgId || p.id === pkgId);

                                            let itemName = elevationName;
                                            if (pkg) {
                                                itemName = `${elevationName} - ${pkg.name}`;
                                            }
                                            const obj = {
                                                id: id + item.id,
                                                unit: unitId,
                                                itemName: itemName,
                                                createdAt: item.createdAt,
                                                user: item.user,
                                                priceBefore: item.data.dataBefore[id],
                                                priceAfter: item.data.dataAfter[id],
                                            };
                                            this.elevationTimelines.push(obj);
                                        }
                                    }
                                );
                            }
                        });
                    } else {
                        const historyTypes = ["units", "premium", "parking", "lockers", "bikeracks"];
                        const [unitsHistory, premiumHistory, parkingHistory, lockersHistory, bikeRacksHistory] = historyTypes.map(type =>
                            data.filter(x => x.data?.body?.type === type)
                        );

                        this.createHistoryData(unitsHistory, "unitTimelines");
                        this.createHistoryData(premiumHistory, "premiumTimelines");
                        this.createHistoryData(parkingHistory, "parkingTimelines");
                        this.createHistoryData(lockersHistory, "lockerTimelines");
                        this.createHistoryData(bikeRacksHistory, "bikeRackTimelines");
                    }
                })
                .catch((err) => {
                    this.$toastError(err, "An error occurred while fetching history data. Please try again");
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                });
        },

		updateScrollVisibility(){
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if(scrollContent && scrollLeftButton && scrollRightButton) {
				if(scrollContent.offsetWidth < scrollContent.scrollWidth){
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},
    },

    created() {
        if (this.instance.productType === "lowrise") {
            this.historyTab = "lots";
        } else {
            this.historyTab = "units";
        }
        this.$store.commit("LOAD", true);
        this.$store.commit("UPDATE_CRUMBS", "priceLog");
        // For the highrise product type, we need to wait for the condo addons to be loaded before fetching the history
		if (this.instance.productType == 'highrise'){
			if (this.condoAddonsLength) {
				this.fetchHistory();
			} else {
				this.unwatchAddOnsChange = this.$watch(
					"condoAddonsLength",
					(newVal) => {
						if (newVal) {
							this.fetchHistory();
							this.unwatchAddOnsChange();
						}
					}
				);
				this.$store.commit("LOAD", false);
			}
		} else {
			this.fetchHistory();
		}
    },

	mounted() {
		this.$nextTick(() => {
			const scrollableArea = document.querySelector('div.ant-table-body')

			var scrollLeftButton = document.createElement('div');
			var scrollRightButton = document.createElement('div');

			scrollLeftButton.className = 'scroll-button scroll-left';
			scrollLeftButton.innerHTML = '&lt;';
			scrollableArea.appendChild(scrollLeftButton);

			scrollRightButton.className = 'scroll-button scroll-right';
			scrollRightButton.innerHTML = '&gt;';
			scrollableArea.appendChild(scrollRightButton);


			scrollLeftButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			});

			scrollRightButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				})
			});
		})

		let scrollableArea = document.querySelector('div.ant-table-body');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		if(scrollableArea){
			this.resizeObserver.observe(scrollableArea);
		}

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
        }
    },
};
</script>

<style>
</style>
