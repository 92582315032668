<template>
	<a-modal :visible="visible" @ok="onCloseDateUpdate" ok-text="Update" @cancel="onClose" :centered="true"
		:confirm-loading="loading">
		<template #title>
			{{ bulkUpdate ? 'Bulk' : '' }} Update {{ instance.productType === 'lowrise' ? 'Tentative/Firm Close Date' : 'Occupancy Date' }}
		</template>
		<div>
			<p v-if="bulkUpdate" class="mb-3 text-md">
				<span v-if="instance.productType === 'lowrise'">
					{{ selectedLots.length }} selected lots will be updated.
				</span>
				<span v-else>
					{{ selectedUnits.length }} selected units will be updated.
				</span>
			</p>
			<a-form-model ref="closeDate" :model="dateModal">
				<template v-if="instance.productType === 'lowrise'">
					<a-form-model-item label="New Tentative/Firm Close Date" prop="closingDate">
						<a-date-picker v-model="dateModal.closingDate" :disabled-date="disabledDate"
							placeholder="YYYY-MM-DD" size="large" style="width:100%" />
					</a-form-model-item>
				</template>
				<template v-else>
					<a-form-model-item label="New Occupancy Date" prop="occupancy">
						<a-date-picker v-model="dateModal.occupancy" :disabled-date="disabledDate"
							placeholder="YYYY-MM-DD" size="large" style="width:100%" />
					</a-form-model-item>
					<a-form-model-item label="New Outside Occupancy Date" prop="outsideClosing">
						<a-date-picker v-model="dateModal.outsideClosing" :disabled-date="disabledDate"
							placeholder="YYYY-MM-DD" size="large" style="width:100%" />
					</a-form-model-item>
				</template>
			</a-form-model>
		</div>
	</a-modal>
</template>

<script>
import moment from "moment";

export default {
	props: [
		"visible",
		"closingDate",
		"occupancy",
		"outsideClosing",
		"selectedLots",
		"selectedUnits",
	],
	data: () => ({
		loading: false,
		dateModal: {
			closingDate: null,
			occupancy: null,
			outsideClosing: null,
		},
	}),
	computed: {
		siteplan() {
			return this.$store.state.inventory.siteplan.id;
		},
		instance() {
			return this.$store.state.instance;
		},
		bulkUpdate() {
			return this.instance.productType === "lowrise" ? this.selectedLots?.length > 1 : this.selectedUnits?.length > 1;
		},
		units() {
			return this.$store.state.inventory.units;
		},
	},
	watch: {
		visible(val) {
			if (val) {
				if (this.instance.productType === "lowrise") {
					this.dateModal.closingDate = this.closingDate ? moment(this.closingDate) : null;
				} else {
					this.dateModal.occupancy = this.occupancy ? moment(this.occupancy) : null;
					this.dateModal.outsideClosing = this.outsideClosing ? moment(this.outsideClosing) : null;
				}
			}
		},
	},
	methods: {
		disabledDate(current) {
			return current && current < moment().startOf('day')
		},

		async onCloseDateUpdate() {
			this.loading = true;
			try {
				const closingDate = this.dateModal.closingDate ? +this.dateModal.closingDate.format("x") : null;
				const occupancy = this.dateModal.occupancy ? +this.dateModal.occupancy.format("x") : null;
				const outsideClosing = this.dateModal.outsideClosing ? +this.dateModal.outsideClosing.format("x") : null;

				if (this.instance.productType === "lowrise") {
					await this.$api.post(`/lots/:instance/bulk-update`, { payload: { closingDate }, ids: this.selectedLots });
				} else {
					await this.$api.post(`/units/:instance/bulk-update-unit-packages`, { payload: { occupancy, outsideClosing }, ids: this.selectedUnits });
				}

				if (this.instance.productType === "lowrise") {
					this.$emit("updateDate", closingDate);
				} else {
					this.$emit("updateDate", { occupancy, outsideClosing });
				}
				this.onClose();
				this.$message.success(`Close date updated successfully.`);
			} catch (err) {
				this.$message.error(this.$err(err, `Error while updating close date. Please try again.`));
			} finally {
				this.loading = false;
			}
		},

		onClose() {
			this.dateModal = {
				closingDate: null,
			};
			this.$emit("closeDateUpdate");
		},
	},
};
</script>

<style></style>
