var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full dF fC f1 px-4 pb-4 hide-scrollbar",staticStyle:{"overflow-y":"scroll"}},[_c('h4',[_vm._v("Close Date History")]),_c('div',{staticClass:"dF aC jSB my-3"},[(_vm.isInstanceLowrise)?_c('div',[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("Tentative/Firm Close Date")])],1):_c('div',[_c('a-button',{attrs:{"type":_vm.dateTab == 'occupancy' ? 'primary' : ''},on:{"click":function($event){_vm.dateTab = 'occupancy'; _vm.search = ''}}},[_vm._v("Occupancy Date")]),_c('a-button',{staticClass:"ml-4",attrs:{"type":_vm.dateTab == 'outsideClosing' ? 'primary' : ''},on:{"click":function($event){_vm.dateTab = 'outsideClosing'; _vm.search = ''}}},[_vm._v("Outside Occupancy Date")])],1),_c('div',[_c('a-input',{staticStyle:{"width":"300px"},attrs:{"placeholder":`Search ${_vm.isInstanceLowrise ? 'lot name' : 'unit number'} Here...`,"allow-clear":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('a-table',{staticClass:"white-table mt-3",attrs:{"columns":_vm.columns,"dataSource":_vm.closeDateHistory,"rowKey":"id","loading":_vm.loading,"pagination":{
			current: _vm.currentPage,
			total: _vm.totalCounts,
			pageSize: _vm.pageSize,
			showSizeChanger: true,
			pageSizeOptions: ['10', '20', '30', '40', '50', '75', '100']
		}},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"lot",fn:function(record){return [_vm._v(" Lot #"+_vm._s(record.collectionData?.name)+" "+_vm._s(record.collectionData?.block ? ` (Block ${record.collectionData?.block})` : '')+" ")]}},{key:"unit",fn:function(record){return [_vm._v(" Unit #"+_vm._s(record.collectionData?.unitNumber)+" ")]}},{key:"previousCloseDate",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataBefore.closingDate))+" ")]}},{key:"newCloseDate",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataAfter.closingDate))+" ")]}},{key:"previousOccupancy",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataBefore.occupancy))+" ")]}},{key:"newOccupancy",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataAfter.occupancy))+" ")]}},{key:"previousOutsideClosing",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataBefore.outsideClosing))+" ")]}},{key:"newOutsideClosing",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.dataAfter.outsideClosing))+" ")]}},{key:"updatedBy",fn:function(record){return [_vm._v(" "+_vm._s(record.updatedBy?.firstName)+" "+_vm._s(record.updatedBy?.lastName)+" ")]}},{key:"updatedAt",fn:function(record){return [_vm._v(" "+_vm._s(_vm.$formatDate(record.updatedAt))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }