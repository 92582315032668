import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import Splash from '@/layouts/Splash'
import AuthLayout from 'bh-mod/layouts/Auth'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					meta: {
						title: 'Inventory',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/unitAddOns',
					meta: {
						title: 'Unit Add Ons Management',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/addon'),
				},
				{
					path: '/allocation',
					meta: {
						title: 'Commission & Allocation Management',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/allocation'),
				},
				{
					path: '/priceManagement',
					meta: {
						title: 'Price Management',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/price'),
				},
				{
					path: '/priceLog',
					meta: {
						title: 'Price History',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/history'),
				},
				{
					path: '/closeDateLog',
					meta: {
						title: 'Price History',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/closeDateHistory'),
				},
				{
					path: '/commissionAllocationHistory',
					meta: {
						title: 'Commission & Allocation History',
						dataRequired: true,
						sidebar: true
					},
					component: () =>
						import('./views/app/allocationHistory'),
				},
				{
					path: '/map',
					meta: {
						title: 'Inventory',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/map'),
				},
				{
					path: '/settings',
					meta: {
						title: 'Settings',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/settings'),
				},
				{
					path: '/details/:id',
					// redirect:'/v3',
					meta: {
						title: 'Inventory Details',
						dataRequired: true,
						highriseOnly: true
					},
					component: () =>
						import('./views/details'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Setup Inventory App',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),

			}]
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
})

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let highriseOnly = to.matched.some(record => record.meta.highriseOnly)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'inventory')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (highriseOnly && store.state.instance.productType !== 'highrise') {
			return finalRouter(to, next);
		}
		let showIntro = store.state.theApp && store.state.theApp.media && store.state.theApp.media.options && store.state.theApp.media.options.showIntro
		if (typeof showIntro !== 'boolean') showIntro = true

		if (splashScreen) {
			if (!showIntro) {
				return finalRouter(to, next);
			}
			if (store.state.inventory.allSettings && store.state.inventory.allSettings.userApp && store.state.inventory.allSettings.userApp.options && store.state.inventory.allSettings.userApp.options.seenIntro) {
				return finalRouter(to, next);
			}
		}
		if (dataRequired) {
			if (showIntro && !store.state.inventory.getStarted && (!store.state.inventory.allSettings.userApp || !store.state.inventory.allSettings.userApp.options || !store.state.inventory.allSettings.userApp.options.seenIntro)) {
				return next('/setup')
			}
		}

	}
	store.commit('PAGE_BUTTONS', [])

	return finalRouter(to, next);
})

function finalRouter(to, next) {
	if (to.path.startsWith("/setup")) {
		return next();
	}
	if (
		(to.path.startsWith("/allocation") || to.path.startsWith("/commissionAllocationHistory") || to.path.startsWith("/priceManagement") || to.path.startsWith("/priceLog")) &&
		(Vue.prototype.$userType === 'agent')
	) {
		return next("/");
	} else if ((to.path.startsWith("/allocation") || to.path.startsWith("/commissionAllocationHistory")) && !store.getters?.isReservationOrWorksheetActive) {
		// restricted allocation and commissionAllocationHistory page to only highrise premium package only and non agent user and activated reservation and worksheet setting
		return next("/");
	}
	return next();
}


export default router
