<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll">
		<BHLoading :show="loading" />
		<h4 class="mb-3"> Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab"
			:width="$userType == 'agent' ? '160' : '250'">
			<a-card v-if="activeTab === 'tags'">
				<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="tags">
					<div slot="name" slot-scope="obj">{{ obj.name }}</div>
					<div slot="actions" slot-scope="tag" class="dF aC" style="justify-content: end; gap: 20px">
						<a-icon type="edit" @click="selectedTag = tag; editModal = true;"/>
						<a-icon type="delete" @click="deleteTag(tag)" style="color: #fd647c;"/>
					</div>
				</a-table>
				<div @click="showTagModal = true" class="dF aC" style="color: var(--orange); cursor: pointer;">
					<a-icon style="font-size: 24px" type="plus-circle" class="mr-3" />
					<div>Add Tag</div>
				</div>
				<a-modal :width="'600px'" centered :header="null" :footer="null" :visible="showTagModal"
					@cancel="showTagModal = false">
					<BHLoading :show="loading" />
					<div>
						<h5>Add New Tag</h5>
						<a-row :gutter="16" class="mt-4">
							<a-form-model ref="newTag" :model="newTag">
								<a-col :span="24">
									<a-form-model-item prop="name" label="Name" required
										:rules="req('Please enter the Name')">
										<a-input v-model="newTag.name" size="large"
											placeholder="Enter the Name"></a-input>
									</a-form-model-item>
								</a-col>
							</a-form-model>
						</a-row>
						<div class="mt-4 dF" style="justify-content: flex-end">
							<a-button @click="showTagModal = false" size="large" class="cancel-button">CANCEL</a-button>
							<a-button @click="submitTag" type="primary" size="large" class="ml-3">CREATE</a-button>
						</div>
					</div>
				</a-modal>

				<a-modal :width="'400px'" centered :header="null" :footer="null" :visible="editModal"
					@cancel="editModal = false">
					<BHLoading :show="loading" />
					<div>
						<h5>Edit Tag</h5>
						<a-row :gutter="16" class="mt-4">
							<a-form-model ref="tag" :model="selectedTag">
								<a-col :span="24">
									<a-form-model-item prop="name" label="Name" required
										:rules="req('Please enter the Name')">
										<a-input v-model="selectedTag.name" size="large"
											placeholder="Enter the Name"></a-input>
									</a-form-model-item>
								</a-col>
							</a-form-model>
						</a-row>
						<div class="mt-4 dF" style="justify-content: flex-end">
							<a-button @click="showTagModal = false" size="large" class="cancel-button">CANCEL</a-button>
							<a-button @click="updateTag" type="primary" size="large" class="ml-3">UPDATE</a-button>
						</div>
					</div>
				</a-modal>
			</a-card>
			<div v-else-if="activeTab === 'commissionStructure'">
				<a-card title="Commission Fee Structure">
					<div class="mt-3" v-if="instance.productType === 'highrise'">
						<a-form-model-item label="Commission Fee Payable">
							<a-radio-group v-model="commissionPayableType" size="large">
								<a-radio-button value="units_and_addons">
									Units Plus Add Ons
								</a-radio-button>
								<a-radio-button value="individual">
									Individual
								</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</div>

					<a-form-model class="mt-3" :model="commissionStructure" ref="commissionStructure">
						<div v-if="instance.productType === 'lowrise'">
							<a-row type="flex" :gutter="24">
								<a-col :span="8">
									<a-form-model-item label="Lot Commission Fee Type" prop="lotCommissionType">
										<a-radio-group v-model="commissionStructure.lotCommissionType" size="large" @change="commissionStructure.lotCommissionAmount = null">
											<a-radio-button value="percentage">
												Percentage
											</a-radio-button>
											<a-radio-button value="amount">
												Amount
											</a-radio-button>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-model-item label="Lot Commission Fee Amount" prop="lotCommissionAmount">
										<a-input-number v-model="commissionStructure.lotCommissionAmount" :formatter="(price) =>
											`${commissionStructure.lotCommissionType === 'amount' ? '$' : ''} ${price} ${commissionStructure.lotCommissionType === 'percentage' ? '%' : ''}`.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
											" :min="0" :disabled="!commissionStructure.lotCommissionIsActive" style="width: 100%" size="large" />
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="Is Active">
										<a-switch
											v-model="commissionStructure.lotCommissionIsActive"
											checked-children="On"
											un-checked-children="Off"
										/>
									</a-form-item>
								</a-col>
							</a-row>
						</div>
						<div v-else-if="commissionPayableType === 'units_and_addons'">
							<a-row type="flex" :gutter="24">
								<a-col :span="24" :lg="12" :xl="8">
									<a-form-model-item label="Commission Fee Type" prop="commissionType">
										<a-radio-group v-model="commissionStructure.commissionType" size="large" @change="commissionStructure.commissionAmount = null">
											<a-radio-button value="percentage">
												Percentage
											</a-radio-button>
											<a-radio-button value="amount">
												Amount
											</a-radio-button>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
								<a-col :span="24" :lg="12" :xl="8">
									<a-form-model-item label="Commission Fee Amount" prop="commissionAmount">
										<a-input-number v-model="commissionStructure.commissionAmount" :formatter="(price) =>
											`${commissionStructure.commissionType === 'amount' ? '$' : ''} ${price} ${commissionStructure.commissionType === 'percentage' ? '%' : ''}`.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
											" :min="0" style="width: 100%" size="large" />
									</a-form-model-item>
								</a-col>
							</a-row>
						</div>
						<div v-else>
							<a-row type="flex" :gutter="24">
								<a-col :span="8">
									<a-form-model-item label="Unit Commission Fee Type" prop="unitCommissionType">
										<a-radio-group v-model="commissionStructure.unitCommissionType" size="large" @change="commissionStructure.unitCommissionAmount = null">
											<a-radio-button value="percentage">
												Percentage
											</a-radio-button>
											<a-radio-button value="amount">
												Amount
											</a-radio-button>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-model-item label="Unit Commission Fee Amount" prop="unitCommissionAmount">
										<a-input-number v-model="commissionStructure.unitCommissionAmount" :formatter="(price) =>
											`${commissionStructure.unitCommissionType === 'amount' ? '$' : ''} ${price} ${commissionStructure.unitCommissionType === 'percentage' ? '%' : ''}`.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
											" :min="0" :disabled="!commissionStructure.unitCommissionIsActive" style="width: 100%" size="large" />
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="Is Active">
										<a-switch
											v-model="commissionStructure.unitCommissionIsActive"
											checked-children="On"
											un-checked-children="Off"
											@change="($event) => {if(!$event) commissionStructure.unitCommissionAmount = null}"
										/>
									</a-form-item>
								</a-col>
							</a-row>
							<a-row type="flex" :gutter="24">
								<a-col :span="8">
									<a-form-model-item label="Add On Commission Fee Type" prop="addOnCommissionType">
										<a-radio-group v-model="commissionStructure.addOnCommissionType" size="large" @change="commissionStructure.addOnCommissionAmount = null">
											<a-radio-button value="percentage">
												Percentage
											</a-radio-button>
											<a-radio-button value="amount">
												Amount
											</a-radio-button>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-model-item label="Add On Commission Fee Amount" prop="addOnCommissionAmount">
										<a-input-number v-model="commissionStructure.addOnCommissionAmount" :formatter="(price) =>
											`${commissionStructure.addOnCommissionType === 'amount' ? '$' : ''} ${price} ${commissionStructure.addOnCommissionType === 'percentage' ? '%' : ''}`.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)
											" :min="0" :disabled="!commissionStructure.addOnCommissionIsActive" style="width: 100%" size="large" />
									</a-form-model-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="Is Active">
										<a-switch
											v-model="commissionStructure.addOnCommissionIsActive"
											checked-children="On"
											un-checked-children="Off"
											@change="($event) => {if(!$event) commissionStructure.addOnCommissionAmount = null}"
										/>
									</a-form-item>
								</a-col>
							</a-row>
						</div>
					</a-form-model>
					<a-button class="pull-right" size="large" type="primary" @click="saveCommissionStructure"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
			<a-card v-else-if="activeTab === 'payoutStructure'">
				<CommissionPayoutTable :data="payoutList" @edit="editPayout" />
				<div @click="payoutVisible" class="mt-3 dF" style="color: var(--orange); cursor: pointer">
					<a-icon style="font-size: 25px" type="plus-circle" class="mr-3" />
					<p>Add new payout</p>
				</div>
				<a-modal centered :header="null" :title="`${!newPayout.edit ? 'Add new' : 'Edit'} Payout`"
					:footer="null" :visible="newPayout.visible" @cancel="closePayout" width="600px">
					<div>
						<a-row :gutter="24">
							<a-form-model ref="newPayout" :model="newPayout">
								<a-col :span="9">
									<a-form-model-item label="Payout Type">
										<a-radio-group v-model="payoutType" size="large">
											<a-radio-button value="date">
												Date
											</a-radio-button>
											<a-radio-button value="stage">
												Stage
											</a-radio-button>
										</a-radio-group>
									</a-form-model-item>
								</a-col>
								<a-col :span="15" v-if="payoutType === 'date'">
									<a-form-model-item prop="date" label="Payout Date"
										help="Enter the number of days after Offer date for the payout" required :rules="{
			validator: checkPayoutDate,
			trigger: 'blur',
		}">
										<a-input-number v-model="newPayout.date" :min="0" :max="99999"
											placeholder="Days" style="width: 100%;" size="large" />
									</a-form-model-item>
								</a-col>
								<a-col :span="15" v-if="payoutType === 'stage'">
									<a-form-model-item prop="stage" label="Payout Stage"
										help="Select the stage of process for this payout" required :rules="{
			required: true,
		}">
										<a-select placeholder="Choose a stage" size="large" v-model="newPayout.stage"
											style="width: 100%;">
											<a-select-option value="execution">
												Execution
											</a-select-option>
											<a-select-option value="closing">
												Closing
											</a-select-option>
										</a-select>
									</a-form-model-item>
								</a-col>
								<a-col :span="24">
									<a-row :gutter="24" class="mt-3">
										<a-col :sm="9">
											<a-form-model-item prop="amount.perc" :label="`Amount Type`">
												<a-radio-group v-model="newPayout.amount.perc" size="large">
													<a-radio-button :value="true">
														Percentage
													</a-radio-button>
													<a-radio-button :value="false">
														Amount
													</a-radio-button>
												</a-radio-group>
											</a-form-model-item>
										</a-col>
										<a-col :sm="15">
											<a-form-model-item prop="amount.value" label="Payout Amount">
												<a-input-number v-if="!newPayout.amount.perc" style="width: 100%"
													:min="0"
													:formatter="(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
													:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
													v-model="newPayout.amount.value" suffix="RMB" />
												<a-input-number v-model="newPayout.amount.value
			" style="width: 100%" v-else :min="0" :max="100" :formatter="(value) => `${value}%`"
													:parser="(value) => value.replace('%', '')" size="large" />
											</a-form-model-item>
										</a-col>
									</a-row>
								</a-col>
							</a-form-model>
						</a-row>
						<div class="dF" style="justify-content: flex-end">
							<a-button @click="newPayout.visible = false" size="large">CANCEL</a-button>
							<a-button @click="addPayout" type="primary" size="large" class="ml-3">{{
			newPayout.edit ? "UPDATE" : "CREATE"
		}}</a-button>
						</div>
					</div>
				</a-modal>
			</a-card>
			<a-card v-else-if="activeTab === 'paymentIntegration'">
				<div class="">
					<h2>Stripe Account Integration
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Connect to stripe account to receive reservation payment
							</template>
							<a-icon type="question-circle" style="font-size: 20px" />
						</a-tooltip>
					</h2>

					<div class="mt-4">
						Status:
						<span v-if="instance.stripeAccountActivated" class="text-success">Connected</span>
						<span v-else class="text-danger">Not Linked to Stripe</span>
					</div>
					<div v-if="instance.stripeAccountActivated">
						<div>
							Name: {{ instance.name }}
						</div>
						<div>
							Account Id: {{ instance.stripeAccountId }}
						</div>
					</div>
					<hr />

					<div class="mt-4">
						<a-button v-if="instance.stripeAccountActivated" size="large" type="danger" icon="minus"
							@click="disconnectStripeAccount" :loading="loading">Remove Integration</a-button>
						<a-button v-else size="large" type="primary" icon="link" @click="connectStripeAccount"
							:loading="loading">Integrate
							with Stripe</a-button>
					</div>

				</div>
			</a-card>
			<div v-else-if="activeTab === 'reservationFeeAndRefund'">
				<a-card>
					<template #title>
						Reservation Fee
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								This allows a builder to set the reservation amount that will be received when reserving a unit.
							</template>
							<a-icon type="question-circle" style="font-size: 14px" />
						</a-tooltip>
					</template>
					<a-form-model class="mt-3" :model="reservationFee" ref="reservationFee">
						<a-row type="flex" :gutter="24">
							<a-col :span="24" :xl="12">
								<a-form-model-item label="Reservation Currency" prop="currency">
									<a-select v-model="reservationFee.currency" size="large">
										<a-select-option value="USD">
											$ USD
										</a-select-option>
										<a-select-option value="CAD">
											$ CAD
										</a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :xl="12">
								<a-form-model-item label="Reservation Fee" prop="value">
									<a-input-number v-model="reservationFee.value"
										:formatter="(price) => `$${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
										:min="0" style="width: 100%" size="large" />
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-form-model>
				</a-card>
				<a-card>
					<template #title>
						Reservation Refund
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Allows builders to determine if they want to refund, partially refund, or offer no
								refunds on the reservation that did not translate into a purchase.
							</template>
							<a-icon type="question-circle" style="font-size: 14px" />
						</a-tooltip>
					</template>
					<a-form-model class="mt-3" :model="reservationRefund" ref="reservationRefund">
						<a-row type="flex" :gutter="24">
							<a-col :span="24" :xl="6">
								<a-form-model-item label="Refund Fee Commission Type" prop="type">
									<a-radio-group v-model="reservationRefund.type" size="large"
										@change="reservationRefund.value = 0">
										<a-radio-button value="percentage">
											Percentage
										</a-radio-button>
										<a-radio-button value="amount">
											Amount
										</a-radio-button>
									</a-radio-group>
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :xl="5">
								<a-form-model-item label="Refund Fee Commission" prop="value">
									<a-input-number v-model="reservationRefund.value" :formatter="(price) =>
			`${reservationRefund.type === 'amount' ? '$' : ''} ${price} ${reservationRefund.type === 'percentage' ? '%' : ''}`.replace(
				/\B(?=(\d{3})+(?!\d))/g,
				','
			)
			" :min="0" style="width: 100%" size="large" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :xl="1"></a-col>
							<a-col :span="24" :xl="5">
								<a-form-model-item label="Refund Fee Commission Amount" prop="value">
									<a-input
										:value="reservationRefundCommission"
										:formatter="(price) => `$${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
										style="width: 100%" size="large" disabled
										:addon-after="reservationFee.currency || 'USD'" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24" :xl="1"></a-col>
							<a-col :span="24" :xl="5">
								<a-form-model-item label="Purchaser Refund Amount" prop="value">
									<a-input :value="purchaserRefundAmount"
										:formatter="(price) => `$${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
										style="width: 100%" size="large" disabled
										:addon-after="reservationFee.currency || 'USD'" />
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-form-model>

					<a-alert type="info" show-icon class="mt-4 mb-5" style="width: max-content;">
						<template slot="message">
							If a refund is issued, you will retain a commission of {{  this.reservationRefundCommission }} ({{ reservationFee.currency }}), and the purchaser will receive a refund of {{ this.purchaserRefundAmount }} ({{ reservationFee.currency }}).
						</template>
					</a-alert>

					<hr style="margin-left: -24px; margin-right: -24px; " />
					<a-button class="pull-right" size="large" type="primary" @click="saveReservationRefundStructure"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
			<div v-else-if="activeTab === 'reservationNotification'">
				<a-card title="Reservation Notification">
					<a-form-model class="mt-3" :model="reservationNotification" ref="reservationNotification">
						<a-row type="flex" :gutter="24">
							<a-col :span="24" :xl="24">
								<a-form-model-item label="Notification Users" prop="users"
									:rules="req('Please select user(s) for reservation notification')">
									<a-select mode="multiple" v-model="reservationNotification.users"
										style="width: 100%" size="large" placeholder="Select Users"
										option-filter-prop="children" :filter-option="filterOption">
										<a-select-option v-for="user in users" :value="user.id" :key="user.id">
											{{ user.firstName }} {{ user.lastName }}
											<span style="font-style: italic;">{{ '(' + user.email + ')' }}</span>
										</a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-form-model>

					<a-button class="pull-right" size="large" type="primary" @click="saveReservationNotification"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
			<div v-else-if="activeTab === 'reservationRelease'">
				<a-card title="Reservation Release">
					<a-form-model class="mt-3" :model="reservationRelease" ref="reservationRelease">
						<a-row type="flex" :gutter="24">
							<a-col :span="24" :xl="24">
								<a-form-model-item label="To VOID the reservation a mutual release is required"
									prop="requiredMutualRelease" required>
									<a-radio-group v-model="reservationRelease.requiredMutualRelease" size="large">
										<a-radio-button :value="true">
											Yes
										</a-radio-button>
										<a-radio-button :value="false">
											No
										</a-radio-button>
									</a-radio-group>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-form-model>

					<a-button class="pull-right" size="large" type="primary" @click="saveReservationRelease"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
			<div v-else-if="activeTab === 'reservationGraceDays'">
				<a-card title="Reservation Grace Days">
					<a-form-model class="mt-3" :model="reservationGraceDays" ref="reservationGraceDays">
						<a-row type="flex" :gutter="24">
							<a-col :span="12">
								<a-form-model-item label="Apply Grace Days to the Reservation" prop="isActive" required>
									<a-radio-group v-model="reservationGraceDays.isActive" size="large">
										<a-radio-button :value="true">
											Yes
										</a-radio-button>
										<a-radio-button :value="false">
											No
										</a-radio-button>
									</a-radio-group>
								</a-form-model-item>
							</a-col>
							<a-col :span="12">
								<a-form-model-item label="Grace Period (in Days)" prop="gracePeriod" v-show="reservationGraceDays.isActive" required>
									<a-input-number :min="1" :max="730" v-model="reservationGraceDays.gracePeriod" size="large"/>
								</a-form-model-item>
							</a-col>
						</a-row>

						<a-alert v-if="!reservationGraceDays.isActive" type="info" show-icon class="mt-3" style="width: max-content;">
							<template slot="message">
								Team members will need to manually void to return the {{ this.instance.productType === "lowrise" ? "lot" : "unit" }} to an available status.
							</template>
						</a-alert>
					</a-form-model>

					<a-button class="pull-right" size="large" type="primary" @click="saveReservationOffer"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
			<div v-else-if="activeTab === 'emailSender'">
				<a-card title="Reservation Email Sender">
					<a-form-model class="mt-3" :model="email" ref="email">
						<a-row :gutter="24">
							<a-col :span="24" :xl="12">
								<a-form-item>
									<template slot="label">
										Select a Sender Email Address
										<a-tooltip overlayClassName="change-tooltip-color">
											<template slot="title">
												The Sender Email Address identifies the address where the email is
												coming
												from. To add more addresses, go to <span><a
														:href="`https://email.bildhive.${$tld}/settings`"
														target="__blank" style="color: var(--orange);">Broadcast Email
														Builder</a></span>
												app.
											</template>
											<a-icon type="question-circle" style="font-size: 14px" />
										</a-tooltip>
									</template>
									<a-select v-model="email.sender" size="large" style="width: 80%">
										<a-select-option value="">
											Default (no-reply@mail.bildhive.com)
										</a-select-option>
										<a-select-option v-for="(email, emailI) in senders" :value="email.id"
											:key="email + emailI">
											{{ email.email }}
										</a-select-option>
									</a-select>
								</a-form-item>
							</a-col>
							<a-col :span="24" :xl="12">
								<a-form-item label="Sender Logo">
									<ImageBoxSelector :removable="true" type="images" :key="email.logo"
										v-model="email.logo" />
								</a-form-item>
							</a-col>
						</a-row>
					</a-form-model>
					<a-button class="pull-right" size="large" type="primary" @click="saveEmailSender"
						:loading="loading">SAVE</a-button>
				</a-card>
			</div>
		</SettingsTab>
	</div>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";
import SettingsTab from 'bh-mod/components/common/SettingsTab'
import CommissionPayoutTable from "@/components/inventory/CommissionPayoutTable";
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	components: {
		BHLoading,
		SettingsTab,
		CommissionPayoutTable,
		ImageBoxSelector
	},
	data() {
		return {
			loading: false,
			showTagModal: false,
			editModal: false,
			columns: [
				{
					title: "Name",
					key: "name",
					scopedSlots: { customRender: "name" },
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				},
			],
			newTag: {
				name: "",
			},
			selectedTag: {},
			activeTab: 'tags',
			payoutType: 'date',
			newPayout: {
				order: 0,
				edit: false,
				visible: false,
				date: 1,
				stage: 'execution',
				amount: {
					value: 1,
					perc: true,
				},
			},
			commissionStructure: {
				commissionType: "percentage",
				commissionAmount: null,
				lotCommissionType: "percentage",
				lotCommissionAmount: null,
				lotCommissionIsActive: false,
				unitCommissionType: "percentage",
				unitCommissionAmount: null,
				unitCommissionIsActive: true,
				addOnCommissionType: "percentage",
				addOnCommissionAmount: null,
				addOnCommissionIsActive: true
			},
			reservationRefund: { type: 'percentage', value: 0 },
			reservationFee: { value: 0, currency: 'USD' },
			reservationNotification: { users: [] },
			reservationRelease: { requiredMutualRelease: true },
			reservationGraceDays: { isActive: false, gracePeriod: 1 },
			commissionPayableType: 'units_and_addons',
			senders: [],
			email: {
				sender: '',
				logo: ''
			}
		};
	},
	computed: {
		tags() {
			let tags = Object.values(this.$store.state.inventory.tags);
			return tags.filter(
				(x) =>
					x &&
					x.name &&
					x.type ===
					(this.instance.productType === "lowrise"
						? "lots"
						: "models")
			);
		},
		storeTags() {
			return this.$store.state.inventory.tags;
		},
		instance() {
			return this.$store.state.instance;
		},
		condoAddons() {
			return this.$store.state.inventory.condoAddons;
		},
		condoSettings() {
			return this.$store.state.inventory.condoSettings;
		},
		units() {
			return Object.values(this.$store.state.inventory.units).filter(
				(unit) => unit.buildingType === "condounits"
			);
		},

		appSettings() {
			let app = this.$store.state.inventory.allSettings.app;
			if (!app) app = { options: {} };
			return app.options;
		},

		payoutList() {
			return this.appSettings.commissionPayoutStructure || [];
		},

		agentCommissionStructure() {
			return this.appSettings.agentCommissionStructure || {
				commissionType: "percentage",
				commissionAmount: null,
				lotCommissionType: "percentage",
				lotCommissionAmount: null,
				lotCommissionIsActive: false,
				unitCommissionType: "percentage",
				unitCommissionAmount: null,
				unitCommissionIsActive: true,
				addOnCommissionType: "percentage",
				addOnCommissionAmount: null,
				addOnCommissionIsActive: true
			};
		},

		agentCommissionType() {
			return this.appSettings.agentCommissionType || 'units_and_addons';
		},

		reservationRefundFees() {
			return this.appSettings.reservationRefundFees || { type: 'percentage', value: 0 };
		},

		reservationNotificationUsers() {
			return this.appSettings.reservationNotification || { users: [] };
		},

		reservationReleaseOption() {
			return this.appSettings.reservationRelease || { requiredMutualRelease: true };
		},

		reservationGraceDaysOption() {
			return this.appSettings.reservationGraceDays || { isActive: false, gracePeriod: 1 };
		},

		reservationAmount() {
			return {
				value: this.appSettings.reservationAmount.value || 0,
				currency: this.appSettings.reservationAmount.currency || 'USD',
			}
		},

		emailSettings() {
			return {
				sender: this.appSettings.email.sender || '',
				logo: this.appSettings.email.logo || 'https://ss3.nyc3.cdn.digitaloceanspaces.com/bh-prod/logo.svg',
			}
		},

		menuList() {
			if (this.$store.getters.isReservationOrWorksheetActive) {
				return [
					{
						label: 'Direct & Worksheet Reservation Settings', id: 'reservation', children: [
							{ label: 'Fee & Refund', id: 'reservationFeeAndRefund' },
							{ label: 'Payment Integration', id: 'paymentIntegration', info: "This is where builder/owner of the account integrates with Stripe to receive the reservation fee payment." },
							{ label: 'Notification', id: 'reservationNotification', info: "This will send an Email/Notifications to the following users of New Reservation." },
							{ label: 'Release', id: 'reservationRelease', info: "This is where the builder can set the rule on whether they want to issue a mutual release against the reservation or allow the builder to automatically release the unit without a mutual release" },
							{ label: 'Grace Days', id: 'reservationGraceDays', info: "This is where the builder can set the rule on whether they want to set grace period to allow the builder to automatically release the unit when offer does not get completed within a given time." },
							{ label: 'Email Sender', id: 'emailSender', info: "This is where a builder can set email sending address for reservation." },
						]
					},
					{
						label: 'Commission Settings', id: 'commission', children: [
							{ label: 'Agent Commission Structure', id: 'commissionStructure', info: "This allows the builder to define the commission amount that will be paid to the agent on a particular unit. The commission structure can be established as a percentage of the sale amount or as a flat fee amount in $." },
							{ label: 'Agent Payout Structure', id: 'payoutStructure', info: "This is where a builder can establish the rules for paying out commissions." },
						]
					},
					{
						label: 'General Settings', id: 'general', children: [{ label: "Tags", id: "tags" }]
					}
				]
			} else {
				return [
					{
						label: 'General Settings', id: 'sub1', children: [{ label: "Tags", id: "tags" }]
					}
				]
			}
		},

		users() {
			return this.$store.state.inventory.users;
		},

		reservationRefundCommission() {
			return (this.reservationFee.value ? (this.reservationFee.value - this.purchaserRefundAmount).toFixed(2) : null)
		},

		purchaserRefundAmount() {
			let reservationFeeValue = this.reservationFee.value
			if (reservationFeeValue) {
				let reservationRefundValue = this.reservationRefund.value
				if (!reservationRefundValue) {
					return reservationFeeValue
				}
				if (this.reservationRefund.type === 'percentage') {
					return (reservationFeeValue - ((reservationFeeValue * reservationRefundValue) / 100)).toFixed(2)
				} else {
					return (reservationFeeValue - reservationRefundValue).toFixed(2);
				}
			}
			return null
		}
	},
	watch: {
		appSettings: {
			deep: true,
			handler(val) {
				this.loading = true;
				this.$api.post(`/settings/:instance/inventory`, {
					options: {
						commissionPayoutStructure: val.commissionPayoutStructure || [],
						agentCommissionStructure: val.agentCommissionStructure || {
							commissionType: 'percentage',
							commissionAmount: null,
							lotCommissionType: "percentage",
							lotCommissionAmount: null,
							lotCommissionIsActive: false,
							unitCommissionType: 'percentage',
							unitCommissionAmount: null,
							unitCommissionIsActive: true,
							addOnCommissionType: 'percentage',
							addOnCommissionAmount: null,
							addOnCommissionIsActive: true,
						},
						reservationRefundFees: val.reservationRefundFees || {},
						reservationAmount: val.reservationAmount || {},
						reservationNotification: val.reservationNotification || { users: [] },
						reservationRelease: val.reservationRelease || { requiredMutualRelease: true },
						reservationGraceDays: val.reservationGraceDays || { isActive: false, gracePeriod: 1 },
						agentCommissionType: val.agentCommissionType || 'units_and_addons',
						email: val.email || { sender: '', logo: '' }
					},
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.loading = false;
				});
			},
		},
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		onTabChange(data) {
			this.activeTab = data;
		},

		submitTag() {
			this.$refs.newTag.validate((valid) => {
				if (valid) {
					this.loading = true;
					let sendObj = JSON.parse(JSON.stringify(this.newTag));
					sendObj.instance = this.instance.id;
					if (this.instance.productType == "lowrise") {
						sendObj.type = "lots";
					} else {
						sendObj.type = "models";
					}

					this.$api
						.post(`/tags/:instance`, sendObj)
						.then(({ data }) => {
							this.$store.commit("ADD_TAG", data);
							this.showTagModal = false;
							this.newTag.name = "";
						})
						.catch((err) => {
							this.$message.error(
								"An error occurred. Please try again"
							);
						})
						.finally(() => {
							this.loading = false;
						});
				} else {
					return false;
				}
			});
		},

		updateTag() {
			this.$refs.tag.validate((valid) => {
				if (valid) {
					this.loading = true;
					let sendObj = JSON.parse(JSON.stringify(this.selectedTag));
					this.$api
						.put(`/tags/:instance/${sendObj.id}`, sendObj)
						.then(({ data }) => {
							this.loading = false;
							this.$store.commit("ADD_TAG", data);
							this.editModal = false;
							this.selectedTag = {};
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						});
				}
			});
		},

		getTags() {
			this.$store.commit("LOAD", true);
			let type = "models";
			if (this.instance.productType === "lowrise") {
				type = "lots";
			}
			this.$api
				.get(`/tags/:instance?type=${type}`)
				.then(({ data }) => {
					this.$store.commit("REMOVE_TAGS");
					this.$store.commit("DO_TAGS", data);
				})
				.catch((err) => {
					this.$message.error("An error occurred. Please try again");
				})
				.finally(() => {
					this.$store.commit("LOAD", false);
				});
		},

		deleteTag(item) {
			if (this.$p < 40) {
				return this.$message.error(
					"You do not have permission to delete this tag"
				);
			}
			let type = "units";
			if (this.instance.productType === "lowrise") {
				type = "lots";
			}
			let self = this;
			this.$confirm({
				title: "Delete",
				content: (h) => <div>Do you want to delete this Tag? By deleting this tag, it will also remove the tag from all the {type} that have this tag applied.</div>,
				okText: "Delete",
				okType: "danger",
				cancelText: "Cancel",
				centered: true,
				onOk() {
					self.$store.commit("LOAD", true);

					self.$api
						.delete(`/tags/:instance/${item.id}`)
						.then(() => {
							self.getTags();
							if (self.instance.productType === "lowrise") {
								self.removeTagFromLots(item)
							} else {
								self.removeTagFromUnits(item)
								self.removeTagFromAddOns(item)
							}
						})
						.finally(() => {
							self.$store.commit("LOAD", false);
						});
				},
			});
		},

		removeTagFromUnits(tag) {
			Object.values(this.$store.state.inventory.units).forEach((unit) => {
				if (unit.tags && unit.tags.length) {
					unit.tags = unit.tags.filter((d) => {
						return d !== tag.id
					});
				}
				this.$store.commit("SET_PROP", {
					where: ["units", unit.id],
					what: unit,
				});
			});
		},

		removeTagFromLots(tag) {
			Object.values(this.$store.state.inventory.lots).forEach((lot) => {
				if (lot.tags && lot.tags.length) {
					lot.tags = lot.tags.filter((d) => {
						return d !== tag.id
					});
				}
				this.$store.commit("SET_PROP", {
					where: ["lots", lot.id],
					what: lot,
				});
			});
		},

		removeTagFromAddOns(tag) {
			Object.keys(this.condoAddons).forEach((addOn) => {
				if (this.condoAddons[addOn] && this.condoAddons[addOn].length) {
					this.condoAddons[addOn].forEach(a => {
						if (a.tags && a.tags.length) {
							const tagLength = a.tags.length;
							a.tags = a.tags.filter((d) => {
								return d !== tag.name
							});
							if (tagLength !== a.tags.length) {
								this.updateAddOn(a, addOn);
							}
						}
						return a
					})

				}
			});
		},

		updateAddOn(updatedAddOn, addOnType) {
			const sendObj = { options: {}, packages: [] };
			const newAddOn = JSON.parse(JSON.stringify(updatedAddOn));
			sendObj.options = JSON.parse(
				JSON.stringify(this.condoSettings.options)
			);

			const foundAddOn = sendObj.options.addons[addOnType].findIndex(
				(x) => x.id == newAddOn.id
			);

			if (foundAddOn !== -1)
				sendObj.options.addons[addOnType][foundAddOn] = newAddOn;

			const condoUnitId = newAddOn.unitId || null

			const unit = this.units.find((x) => x.id === condoUnitId);
			if (unit) {
				if (unit.packages && unit.packages.length) {
					if (!unit.packages[0].other.addons) {
						unit.packages[0].other.addons = {
							parking: [],
							lockers: [],
							bikeRacks: [],
						};
					} else if (!unit.packages[0].other.addons[addOnType]) {
						unit.packages[0].other.addons[addOnType] = [];
					}

					const foundPackageAddOn = unit.packages[0].other.addons[
						addOnType
					].findIndex((x) => x.id == newAddOn.id);

					if (foundPackageAddOn !== -1)
						if (newAddOn.unitId) {
							unit.packages[0].other.addons[addOnType][
								foundPackageAddOn
							] = newAddOn;
						} else {
							unit.packages[0].other.addons[addOnType].splice(
								foundPackageAddOn,
								1
							);
						}
					else {
						unit.packages[0].other.addons[addOnType].push(newAddOn);
					}

					sendObj.packages = unit.packages;

					this.$api
						.put(
							`/units/:instance/${condoUnitId}/condounits`,
							sendObj
						)
						.then(() => {
							this.$store.commit(
								"UPDATE_CONDOADDONS",
								sendObj.options.addons
							);
							this.$store.commit("UPDATE_CONDO_SETTINGS", {
								...this.condoSettings,
								options: sendObj.options,
							});
							this.$store.commit("UPDATE_UNITS", unit);
						})
						.catch((err) => {
							console.error(
								"Error error occurred while updating addon",
								err
							);
						});
				}
			} else {
				sendObj.options.addons[addOnType][foundAddOn] = {
					...newAddOn,
					unitId: "",
				};
				this.$api
					.put(`/settings/:instance/condounits`, {
						options: sendObj.options,
					})
					.then(({ data }) => {
						this.$store.commit(
							"UPDATE_CONDOADDONS",
							sendObj.options.addons
						);
						this.$store.commit("UPDATE_CONDO_SETTINGS", data);
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},

		addPayout() {
			this.$refs.newPayout.validate((valid) => {
				if (valid) {
					let newPayout = JSON.parse(
						JSON.stringify(this.newPayout)
					);
					let { edit, visible, ...obj } = newPayout;

					if (this.payoutType === 'date') {
						delete obj.stage
					} else {
						delete obj.date
					}

					if (this.newPayout.edit) {
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "commissionPayoutStructure",
							action: "arrayEdit",
							what: obj,
							key: "order",
						});
					} else {
						obj.order = this.payoutList.length || 0;
						this.$store.commit("SET_OPTIONS", {
							type: "app",
							where: "commissionPayoutStructure",
							action: "arrayAdd",
							what: obj,
						});
					}

					this.closePayout();
				} else {
					return false;
				}
			});
		},

		editPayout(item) {
			item = JSON.parse(JSON.stringify(item));
			if (!item.date) {
				this.payoutType = 'stage'
			} else {
				this.payoutType = 'date'
			}
			this.newPayout = {
				...this.newPayout,
				edit: true,
				visible: true,
				...item,
			};
		},

		closePayout() {
			this.$refs.newPayout && this.$refs.newPayout.resetFields();
			this.payoutType = 'date'
			this.newPayout = {
				order: 0,
				edit: false,
				visible: false,
				date: 1,
				stage: 'execution',
				amount: {
					value: 1,
					perc: true,
				},
			}
		},

		payoutVisible() {
			this.payoutType = 'date'
			this.newPayout = {
				order: 0,
				edit: false,
				visible: true,
				date: 1,
				stage: 'execution',
				amount: {
					value: 1,
					perc: true,
				},
			}
			this.$nextTick(() => {
				this.$refs.newPayout.resetFields();
				this.newPayout.order = this.payoutList.length;
			})
		},

		checkPayoutDate(rule, value, callback) {
			let prevError = this.payoutList.filter(p => p.date).find((x) => {
				return x.order < this.newPayout.order && x.date > value;
			});

			if (prevError)
				return callback(
					new Error(
						"Payout Days must be greater than previous Payouts"
					)
				);

			let futureError = this.payoutList.filter(p => p.date).find((x) => {
				if (x.order > this.newPayout.order) {
					return x.date < value;
				}
				return false;
			});

			if (futureError)
				return callback(
					new Error(
						"Payout Days must be lesser than future Payouts"
					)
				);

			callback();
		},

		saveCommissionStructure() {
			this.$refs.commissionStructure.validate((valid) => {
				if (valid) {
					let commissionStructure = JSON.parse(
						JSON.stringify(this.commissionStructure)
					);

					if (this.instance.productType === 'lowrise') {
						this.commissionPayableType = 'individual'
						this.commissionStructure.unitCommissionIsActive = false
						this.commissionStructure.addOnCommissionIsActive = false
					} else {
						this.commissionStructure.lotCommissionIsActive = false
					}

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "agentCommissionStructure",
						what: commissionStructure,
					});

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "agentCommissionType",
						what: this.commissionPayableType,
					});
				} else {
					return
				}
			})
		},

		saveReservationRefundStructure() {
			if (this.reservationRefund.value && this.reservationRefund.type === 'percentage' && this.reservationRefund.value > 100) return this.$message.error('Reservation refund percentage cannot be more than 100. Please enter correct value.')
			if (this.reservationRefund.value && this.reservationRefund.type === 'amount' && this.reservationRefund.value > this.reservationFee.value) return this.$message.error('Reservation refund amount cannot be more than reservation fee amount. Please enter correct value.')
			this.saveReservationFee();

			this.$refs.reservationRefund.validate((valid) => {
				if (valid) {
					let reservationRefund = JSON.parse(
						JSON.stringify(this.reservationRefund)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "reservationRefundFees",
						what: reservationRefund,
					});
				} else {
					return
				}
			})
		},

		saveReservationFee() {
			if (this.reservationFee.value && this.reservationFee.value < 100) return this.$message.error('Reservation amount cannot be less than $100. Minimum $100 is required.')

			this.$refs.reservationFee.validate((valid) => {
				if (valid) {
					let reservationFee = JSON.parse(
						JSON.stringify(this.reservationFee)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "reservationAmount",
						what: reservationFee,
					});
				} else {
					return
				}
			})
		},

		saveReservationNotification() {
			this.$refs.reservationNotification.validate((valid) => {
				if (valid) {
					let reservationNotification = JSON.parse(
						JSON.stringify(this.reservationNotification)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "reservationNotification",
						what: reservationNotification,
					});
				} else {
					return
				}
			})
		},

		saveReservationRelease() {
			this.$refs.reservationRelease.validate((valid) => {
				if (valid) {
					let reservationRelease = JSON.parse(
						JSON.stringify(this.reservationRelease)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "reservationRelease",
						what: reservationRelease,
					});
				} else {
					return
				}
			})
		},

		saveReservationOffer() {
			this.$refs.reservationGraceDays.validate((valid) => {
				if (valid) {
					let reservationGraceDays = JSON.parse(
						JSON.stringify(this.reservationGraceDays)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "reservationGraceDays",
						what: reservationGraceDays,
					});
				} else {
					return
				}
			})
		},

		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text
					.toLowerCase()
					.indexOf(input.toLowerCase()) >= 0
			);
		},

		saveEmailSender() {
			this.$refs.email.validate((valid) => {
				if (valid) {
					let email = JSON.parse(
						JSON.stringify(this.email)
					);

					this.$store.commit("SET_OPTIONS", {
						type: "app",
						where: "email",
						what: email,
					});
				} else {
					return
				}
			})
		},

		async connectStripeAccount() {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`/instances/:instance/connect-stripe-account`)
				if (data.url) {
					window.location.href = data.url;
				}
			} catch (err) {
				console.error('Error while connecting strip account.', err)
				this.$message.error(
					(err.response &&
						err.response.data &&
						err.response.data.message) ||
					"Error while connecting strip account. Please try again!"
				);
			}
			this.loading = false;
		},

		async disconnectStripeAccount() {
			if (this.$p < 40) {
				return this.$message.error("You do not have permission to remove integration");
			}
			let self = this;
			this.$confirm({
				title: `Disconnect Stripe Account`,
				content: (h) => (
					<div>Do you want to remove stripe connection?</div>
				),
				okText: "DISCONNECT",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: async () => {
					self.loading = true;
					try {
						await self.$api.post(`/instances/:instance/deactivate-stripe-connection`)
						self.$store.commit('UPDATE_INSTANCE', { stripeAccountActivated: false })
						self.$message.success('Stripe connection removed successfully.')
					} catch (err) {
						console.error('Error while disconnecting strip account.', err)
						self.$message.error(
							(err.response &&
								err.response.data &&
								err.response.data.message) ||
							"Error while disconnecting strip account. Please try again!"
						);
					}
					self.loading = false;
				},
			});
		},

		async fetchSenders() {
			try {
				const { data } = await this.$api.get(`/senders/:instance`)
				this.senders = data;
			} catch (err) {
				console.error('Error while fetching senders data.', err)
			}
		}
	},
	created() {
		if (this.$store.getters.isReservationOrWorksheetActive) {
			this.activeTab = 'reservationFeeAndRefund'
		}

		this.fetchSenders();

		this.$store.commit("UPDATE_CRUMBS", "settings");
		const tags = Object.values(this.storeTags);
		if (
			(tags.length && tags.filter((x) => x && x.name).length == 0) ||
			!tags.length
		) {
			this.getTags();
		}

		this.commissionStructure = {
			commissionType: 'percentage',
			commissionAmount: null,
			lotCommissionType: "percentage",
			lotCommissionAmount: null,
			lotCommissionIsActive: false,
			unitCommissionType: 'percentage',
			unitCommissionAmount: null,
			unitCommissionIsActive: true,
			addOnCommissionType: 'percentage',
			addOnCommissionAmount: null,
			addOnCommissionIsActive: true,
			...this.agentCommissionStructure
		};
		this.reservationRefund = { ...this.reservationRefundFees };
		this.reservationNotification = { ...this.reservationNotificationUsers }
		this.reservationRelease = { ...this.reservationReleaseOption }
		this.reservationGraceDays = { ...this.reservationGraceDaysOption }
		this.reservationFee = { ...this.reservationAmount };
		this.commissionPayableType = this.agentCommissionType !== 'individual' ? 'units_and_addons' : 'individual';
		this.email = this.emailSettings;

		if (this.$route.query["payments-connection"]) {
			this.onTabChange('paymentIntegration')
		}
	},
};
</script>

<style></style>
